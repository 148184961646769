'use strict';

import BreadcrumbStore             from './stores/BreadcrumbStore';
import BrowserStore                from './stores/BrowserStore';
import ConversionStore             from './stores/ConversionStore';
import FavoriteStore               from './stores/FavoriteStore';
import ActorStore                  from './stores/ActorStore';
import GuestStore                  from './stores/GuestStore';
import LoggerStore                 from './stores/LoggerStore';
import TrackerStore                from './stores/TrackerStore';
import MessengerActorStore         from './stores/MessengerActorStore';
import MessengerChannelsStore      from './stores/MessengerChannelsStore';
import MessengerStore              from './stores/MessengerStore';
import NotificationStore           from './stores/NotificationStore';
import ProSearchMetaStore          from './stores/ProSearchMetaStore';
import SpecialStore                from './stores/SpecialStore';
import SurveyStore                 from './stores/SurveyStore';
import TeaserStore                 from './stores/TeaserStore';
import TileGridStore               from './stores/TileGridStore';
import VXMobileStore               from './stores/VXMobileStore';
import ActionCreator               from './actions/ActionCreator';
import ProSearchActionCreator      from './actions/ProSearchActionCreator';
import ActorActionCreator          from './actions/ActorActionCreator';
import VXMobileActionCreator       from './actions/VXMobileActionCreator';
import ChatExitCodes               from './ChatExitCodes';
import Constants                   from './Constants';
import GalleryStore                from './stores/GalleryStore';
import TVMediaStore                from './stores/TVMediaStore';
import TVLivestreamStore           from './stores/TVLivestreamStore';
import ContestStore                from './stores/ContestStore';
import BlogPostsStore              from './stores/BlogPostsStore';
import BlogActorsStore             from './stores/BlogActorsStore';
import MessengerGiftStore          from './stores/MessengerGiftStore';
import MessengerToyControlStore    from './stores/MessengerToyControlStore';
import VxqlStore                   from './stores/VxqlStore';
import VideoJumbotronStore         from './stores/VideoJumbotronStore';
import VideoJumbotronActionCreator from './actions/VideoJumbotronActionCreator';
import VideoFilterStore            from './stores/VideoFilterStore';
import VideoFilterActionCreator    from './actions/VideoFilterActionCreator';
import FooterLinksStore            from './stores/FooterLinksStore';
import BlogPostsActionCreator      from './actions/BlogPostsActionCreator';
import VideoStarsStore             from './stores/VideoStarsStore';
import PromoStore                  from './stores/PromoStore';
import GlobalSearchStore           from './stores/GlobalSearchStore';
import SearchStore                 from './stores/SearchStore';
import DailyBonusStore             from './stores/DailyBonusStore';
import DailyBonusActionCreator     from './actions/DailyBonusActionCreator';
import VideoV2Store                from './stores/VideoV2Store';
import ActorsStore                 from './stores/ActorsStore';
import ClashOfCamsStore            from './stores/ClashOfCamsStore';
import LiveReactionsStore          from "./stores/LiveReactionsStore";
import TagsStore                   from './stores/TagsStore';
import TVSeriesStore               from "./stores/TVSeriesStore";
import SearchResultStore           from "./stores/SearchResultStore";
import FeedStore                   from './stores/FeedStore';
import SlotmachineStore            from './stores/SlotmachineStore';
import GalleriesStore from './stores/GalleriesStore';

const stores = {
//	ACTOR:        'actor',
//	COLLECTION:   'collection',
	MESSENGER:    'messenger',
	GIFT:         'gift',
	TOYCONTROL:   'toyControl',
	NOTIFICATION: 'notification',
	PROSEARCH:    'prosearch',
//	TVLivestream: 'tvlivestream',
};

const initHelper = (function() {

	const storesReady = {};

	const onReadyListeners = {};

	return {

		isReady: function(store) {
			return (typeof storesReady[store] === 'undefined' ? false : storesReady[store]);
		},

		resetReadyState: function(store) {
			storesReady[store] = false;
		},

		setReady: function(store) {
			storesReady[store] = true;
			this.callOnReadyListeners(store);
		},

		addReadyListener: function(store, listener) {
			if (typeof listener === 'function') {
				if (typeof onReadyListeners[store] === 'undefined') {
					onReadyListeners[store] = []; // init onReadyListeners
				}
				onReadyListeners[store].push(listener);
				this.callOnReadyListeners(store);
			}
		},

		callOnReadyListeners: function(store) {
			if (this.isReady(store)) {
				if (typeof onReadyListeners[store] !== 'undefined') {
					for (const listener of onReadyListeners[store]) {
						listener();
					}
				}
				onReadyListeners[store] = undefined;
			}
		},

	};
})();

const Flux = {

	Gallery: {

		initGalleryData: function(albumId, data) {
			return GalleryStore.initGalleryData(albumId, data);
		},

		getGalleryData: function(albumId) {
			return GalleryStore.getGalleryData(albumId);
		},

		getPictureData: function(pictureId) {
			return GalleryStore.getPictureData(pictureId);
		},

		getCommentsData: function(albumId) {
			return GalleryStore.getCommentsData(albumId);
		},

		updateGalleryData: function(albumId, useCache) {
			ActionCreator.Gallery.updateGalleryData(albumId, useCache);
		},

		addComment: function(albumId, commentText) {
			ActionCreator.Gallery.addComment(albumId, commentText);
		},

		showMoreComments: function(albumId) {
			ActionCreator.Gallery.showMoreComments(albumId);
		},

		setGuestRating: function(albumId, rating) {
			ActionCreator.Gallery.setGuestRating(albumId, rating);
		},

		setPictureGuestRating: function(pictureId, rating) {
			ActionCreator.Gallery.setPictureGuestRating(pictureId, rating);
		},

		buyAlbum: function(albumId, trackerContext = {}) {
			ActionCreator.Gallery.buyAlbum(albumId, trackerContext);
		},

		addGalleryChangeListener: function(callback) {
			GalleryStore.addGalleryChangeListener(callback);
		},

		removeGalleryChangeListener: function(callback) {
			GalleryStore.removeGalleryChangeListener(callback);
		},

		addGalleryRatingChangeListener: function(callback) {
			GalleryStore.addGalleryRatingChangeListener(callback);
		},

		removeGalleryRatingChangeListener: function(callback) {
			GalleryStore.removeGalleryRatingChangeListener(callback);
		},

		addPictureRatingChangeListener: function(callback) {
			GalleryStore.addPictureRatingChangeListener(callback);
		},

		removePictureRatingChangeListener: function(callback) {
			GalleryStore.removePictureRatingChangeListener(callback);
		},

		addCommentsChangeListener: function(callback) {
			GalleryStore.addCommentsChangeListener(callback);
		},

		removeCommentsChangeListener: function(callback) {
			GalleryStore.removeCommentsChangeListener(callback);
		},

		addPinnedPhotosChangeListener: function(callback) {
			GalleryStore.addPinnedPhotosChangeListener(callback);
		},

		removePinnedPhotosChangeListener: function(callback) {
			GalleryStore.removePinnedPhotosChangeListener(callback);
		},

		initSedcardsData: function(actorId, data) {
			return GalleryStore.initSedcardsData(actorId, data);
		},

		loadSedcards: function(actorId) {
			ActionCreator.Gallery.loadSedcards(actorId);
		},

		getSedcards: function(actorId) {
			return GalleryStore.getSedcards(actorId);
		},

		setSedcardsGuestRating: function(actorId, rating) {
			ActionCreator.Gallery.setSedcardsGuestRating(actorId, rating);
		},

		loadGallery: function(albumId, params) {
			GalleryStore.loadGallery(albumId, params);
		},

		loadPinnedPhotos: function(params) {
			GalleryStore.loadPinnedPhotos(params);
		},

		getPinnedPhotos: function(params) {
			return GalleryStore.getPinnedPhotos(params);
		},

		getCurrentVideoTime: function() {
			return GalleryStore.getCurrentVideoTime();
		},

		setCurrentVideoTime: function(currentTime) {
			GalleryStore.setCurrentVideoTime(currentTime);
		},

		addCurrentVideoTimeChangeListener: function(callback) {
			GalleryStore.addCurrentVideoTimeChangeListener(callback);
		},

		removeCurrentVideoTimeChangeListener: function(callback) {
			GalleryStore.removeCurrentVideoTimeChangeListener(callback);
		},

		isCinematicModeEnabled: function() {
			return GalleryStore.isCinematicModeEnabled();
		},

		setCinematicModeEnabled: function(enabled) {
			GalleryStore.setCinematicModeEnabled(enabled);
		},

		addCurrentVideoCinematicModeListener: function(callback) {
			GalleryStore.addCurrentVideoCinematicModeListener(callback);
		},

		removeCurrentVideoCinematicModeListener: function(callback) {
			GalleryStore.removeCurrentVideoCinematicModeListener(callback);
		},

		getPreview: function() {
			return GalleryStore.getPreview();
		},

		loadPreview: function(actorName, albumId, isMobile) {
			ActionCreator.Gallery.loadPreview(actorName, albumId, isMobile);
		},

		addPreviewChangeListener: function(callback) {
			GalleryStore.addPreviewChangeListener(callback);
		},

		removePreviewChangeListener: function(callback) {
			GalleryStore.removePreviewChangeListener(callback);
		},

		/**
		 * @param {function} callback
		 */
		addFocusCommentsInputChangeListener: function(callback) {
			GalleryStore.addFocusCommentsInputChangeListener(callback);
		},

		/**
		 * @param {function} callback
		 */
		removeFocusCommentsInputChangeListener: function(callback) {
			GalleryStore.removeFocusCommentsInputChangeListener(callback);
		},

		focusCommentsInput: function() {
			GalleryStore.focusCommentsInput();
		},

		/**
		 * @param {function} callback
		 */
		addEnableFeedbackListener: function(callback) {
			GalleryStore.addEnableFeedbackListener(callback);
		},

		/**
		 * @param {function} callback
		 */
		removeEnableFeedbackListener: function(callback) {
			GalleryStore.removeEnableFeedbackListener(callback);
		},

		enableFeedback: function() {
			GalleryStore.enableFeedback();
		},

        pauseVideo: function() {
            GalleryStore.pauseVideo();
        },
        addVideoPauseListener: function(callback) {
            GalleryStore.addVideoPauseListener(callback);
        },
        removeVideoPauseListener: function(callback) {
            GalleryStore.removeVideoPauseListener(callback);
        }
	},

	Breadcrumb: {
		loadBreadcrumb: function(breadcrumbType, data, breadcrumbKey) {
			ActionCreator.Breadcrumb.loadBreadcrumb(breadcrumbType, data, breadcrumbKey);
		},

		getBreadcrumb: function(key) {
			return BreadcrumbStore.getBreadcrumb(key);
		},

		addBreadcrumbChangeListener: function(callback) {
			BreadcrumbStore.addBreadcrumbChangeListener(callback);
		},

		removeBreadcrumbChangeListener: function(callback) {
			BreadcrumbStore.removeBreadcrumbChangeListener(callback);
		},
	},

	Browser: {

		isMobileMenuVisible: function() {
			return BrowserStore.isMobileMenuVisible();
		},

		addPreloadedMediaChangeListener: function(callback) {
			BrowserStore.addPreloadedMediaChangeListener(callback);
		},

		removePreloadedMediaChangeListener: function(callback) {
			BrowserStore.removePreloadedMediaChangeListener(callback);
		},

		addShowStatusBarChangeListener: function(callback) {
			BrowserStore.addShowStatusBarChangeListener(callback);
		},

		addStatusBarWindowClickListener: function(callback) {
			BrowserStore.addStatusBarWindowClickListener(callback);
		},

		removeStatusBarWindowClickListener: function(callback) {
			BrowserStore.removeShowStatusBarWindowClickListener(callback);
		},

		removeShowStatusBarChangeListener: function(callback) {
			BrowserStore.removeShowStatusBarChangeListener(callback);
		},

		setShowPhoneAccess: function(show) {
			BrowserStore.setShowPhoneAccess(show);
		},

		getShowPhoneAccess: function() {
			return BrowserStore.getShowPhoneAccess();
		},

		addShowPhoneAccessChangeListener: function(callback) {
			BrowserStore.addShowPhoneAccessChangeListener(callback);
		},

		removeShowPhoneAccessChangeListener: function(callback) {
			BrowserStore.removeShowPhoneAccessChangeListener(callback);
		},

		addMobileMenuChangeListener: function(callback) {
			BrowserStore.addMobileMenuChangeListener(callback);
		},

		removeMobileMenuChangeListener: function(callback) {
			BrowserStore.removeMobileMenuChangeListener(callback);
		},

		addWindowResizeListener: function(callback) {
			BrowserStore.addWindowResizeListener(callback);
		},

		removeWindowResizeListener: function(callback) {
			BrowserStore.removeWindowResizeListener(callback);
		},

		addOnlineChangeListener: function(callback) {
			BrowserStore.addOnlineChangeListener(callback);
		},

		removeOnlineChangeListener: function(callback) {
			BrowserStore.removeOnlineChangeListener(callback);
		},

		addVisibilityChangeListener: function(callback) {
			BrowserStore.addVisibilityChangeListener(callback);
		},

		removeVisibilityChangeListener: function(callback) {
			BrowserStore.removeVisibilityChangeListener(callback);
		},

		historyPushState: function(stateObj, title, url) {
			BrowserStore.historyPushState(stateObj, title, url);
		},

		historyReplaceState: function(stateObj, title, url) {
			BrowserStore.historyReplaceState(stateObj, title, url);
		},

		addWindowPopStateListener: function(callback) {
			BrowserStore.addWindowPopStateListener(callback);
		},

		/**
		 * @param {Function} callback
		 * @param {Boolean} passive
		 */
		addWindowScrollListener: function(callback, passive = false) {
			BrowserStore.addWindowScrollListener(callback, passive);
		},

		/**
		 * @param {Function} callback
		 * @param {Boolean} passive
		 */
		removeWindowScrollListener: function(callback, passive = false) {
			BrowserStore.removeWindowScrollListener(callback, passive);
		},

		removeWindowPopStateListener: function(callback) {
			BrowserStore.removeWindowPopStateListener(callback);
		},

		getLocalItem: function(key) {
			return BrowserStore.getLocalItem(key);
		},

		setLocalItem: function(key, data) {
			BrowserStore.setLocalItem(key, data);
		},

		preloadMedia: function(url, useAuthentication) {
			ActionCreator.Browser.preloadMedia(url, useAuthentication);
		},

		getPreloadedMediaUrl: function(url) {
			return BrowserStore.getPreloadedMediaUrl(url);
		},

		removeLocalItem: function(key) {
			BrowserStore.removeItem(key);
		},

		setSupportChatHidden: function() {
			BrowserStore.setLocalItem('supportChat', true);
		},

		getConnection: function() {
			return BrowserStore.getConnection();
		},

		/**
		 * @return {{width: number, height: number}}
		 */
		getWindowSize: function() {
			return BrowserStore.getWindowSize();
		},

		/**
		 * @return string
		 */
		getViewPort: function() {
			return BrowserStore.getViewPort();
		},

		/**
		 * @return {*|Location|string|any|{line, column}}
		 */
		getLocation: function() {
			return BrowserStore.getLocation();
		},

		isAndroid: function() {
			return BrowserStore.isAndroid();
		},

		isChromeIOS: function() {
			return BrowserStore.isChromeIOS();
		},

		isFirefoxIOS: function() {
			return BrowserStore.isFirefoxIOS();
		},

		isIE: function() {
			return BrowserStore.isIE();
		},

		isEdge: function() {
			return BrowserStore.isEdge();
		},

		isLandscape: function() {
			return BrowserStore.isLandscape();
		},

		isOnline: function() {
			return BrowserStore.isOnline();
		},

		isPortrait: function() {
			return BrowserStore.isPortrait();
		},

		isSafariMobile: function() {
			return BrowserStore.isSafariMobile();
		},

        isSafari: function() {
			return BrowserStore.isSafari();
		},

		isSupportChatHidden: function() {
			return BrowserStore.getLocalItem('supportChat');
		},

		showsBlankFlashArea: function() {
			return BrowserStore.showsBlankFlashArea();
		},

		supportsFlexbox: function() {
			return BrowserStore.supportsFlexbox();
		},

		supportsHlsNative: function() {
			return BrowserStore.supportsHlsNative();
		},

		supportsHtmlUpstreamOnly: function() {
			return BrowserStore.supportsHtmlUpstreamOnly();
		},

		supportsUpstream: function() {
			return BrowserStore.supportsUpstream();
		},

		updateExpireStamp: function(ttl) {
			if (ttl > 0) {
				BrowserStore.setLocalItem('expat', (Date.now() + ttl * 1000));
			}
		},

		checkExpireStamp: function() {
			const expireStamp = parseInt(BrowserStore.getLocalItem('expat'));
			const result      = expireStamp && (Date.now() > expireStamp);

			if (result) {
				BrowserStore.removeItem('expat');
			}

			return result;
		},

		setShowStatusBar: function(showStatusBar) {
			ActionCreator.Browser.setShowStatusBar(showStatusBar);
		},

		addShouldModalWindowCloseListener: function(callback) {
			BrowserStore.addShouldModalWindowCloseListener(callback);
		},

		setShouldModalWindowClose: function() {
			ActionCreator.Browser.closeModalWindow();
		},

		isTestDevice: function() {
			return window.location.search && window.location.search.indexOf('testdevice') > -1;
		},

		isTablet: function() {
			return BrowserStore.isTablet();
		},

		isTouch: function() {
			return BrowserStore.isTouch();
		},

		isSexole: function() {
			return BrowserStore.isSexole();
		},

		isSpanishPfm: function() {
			return BrowserStore.isSpanishPfm();
		},

		getAvsPictureUrl: function() {
			return BrowserStore.getAvsPictureUrl();
		},

		isMobileView: function() {
			return BrowserStore.isMobileView();
		},

		isTabletView: function() {
			return BrowserStore.isTabletView();
		},
	},

	Conversion: {
		addIncentivesChangeListener: function(callback) {
			ConversionStore.addIncentivesChangeListener(callback);
		},

		removeIncentivesChangeListener: function(callback) {
			ConversionStore.removeIncentivesChangeListener(callback);
		},

		addIncentiveCloseListener: function(callback) {
			ConversionStore.addIncentiveCloseListener(callback);
		},

		removeIncentiveCloseListener: function(callback) {
			ConversionStore.removeIncentiveCloseListener(callback);
		},

		getExitIntentIncentive: function() {
			return ConversionStore.getExitIntentIncentive();
		},

		getExitIntentExtendedIncentive: function() {
			return this.getIncentiveByRegion(Constants.ConversionIncentiveRegions.EXIT_INTENT_EXTENDED);
		},

		getExitIntentLogoutIncentive: function() {
			return this.getIncentiveByRegion(Constants.ConversionIncentiveRegions.EXIT_INTENT_LOGOUT);
		},

		getIncentiveByRegion: function(region) {
			return ConversionStore.getIncentiveByRegion(region);
		},

		clearRegion: function(region) {
			ConversionStore.clearRegion(region);
		},

		closeIncentive: function(incentive, data) {
			ActionCreator.Conversion.closeIncentive(incentive, data);
		},

		loadBuyImageAlbumIncentives: function(albumId) {
			ActionCreator.Conversion.loadBuyImageAlbumIncentives(albumId);
		},

		loadIncentivesByEvent: function(event) {
			ActionCreator.Conversion.loadIncentivesByEvent(event);
		},

		getVXPayConfig: function() {
			return ConversionStore.getVXPayConfig();
		},

		setVXPayConfig: function(vxpayConfig) {
			ConversionStore.setVXPayConfig(vxpayConfig);
		},

		getTrackingData: function() {
			return ConversionStore.getTrackingData();
		},

		/**
		 * Params are only to be set via mobile requests!
		 *
		 * @param {string} routeName
		 * @param {object} routeArgs
		 */
		loadPageLoadIncentives: function(routeName = '', routeArgs = null) {
			ActionCreator.Conversion.loadPageLoadIncentives(routeName, routeArgs);
		},

		loadVideoPreviewEndIncentives: function(albumId) {
			ActionCreator.Conversion.loadVideoPreviewEndIncentives(albumId);
		},

		setGuestAlreadySignedUp: function(alreadySignedUp) {
			ActionCreator.Conversion.setGuestAlreadySignedUp(alreadySignedUp);
		},

		setGuestInfo: function(infoType, data) {
			ActionCreator.Conversion.setGuestInfo(infoType, data);
		},

		setConversionWindowHidden: function(incentiveType, interval, incentiveRegion) {
			ActionCreator.Conversion.setConversionWindowHidden(incentiveType, interval, incentiveRegion);
		},

		addRefillWindowHiddenChangeListener: function(callback) {
			ConversionStore.addRefillWindowHiddenChangeListener(callback);
		},

		removeRefillWindowHiddenChangeListener: function(callback) {
			ConversionStore.removeRefillWindowHiddenChangeListener(callback);
		},

		blockIncentive: function(incentive, data) {
			ConversionStore.blockIncentive(incentive, data);
		},

		isIncentiveBlocked: function(incentive) {
			return ConversionStore.isIncentiveBlocked(incentive);
		},
	},

	Logger: {
		init: function() {
			LoggerStore.init();
		},

		logWebsocketConnectionError: function(error, websocketType, errorCode, errorReason, connectionAttempt) {
			LoggerStore.logWebsocketConnectionError(error, websocketType, errorCode, errorReason, connectionAttempt);
		},

		logVXMessengerError: function(message) {
			LoggerStore.logVXMessengerError(message);
		}
	},

	Tracker: {
		track: function(eventContext, eventAction, eventData) {
			TrackerStore.track(eventContext, eventAction, eventData);
		},

		trackActorProfileVisit: function(actorName) {
			TrackerStore.trackActorProfileVisit(actorName);
		},

		getEventTracker: function() {
			return TrackerStore.getEventTracker();
		},
	},

	Tags: {
		searchTags: function(search, callback) {
			TagsStore.searchTags(search, callback);
		},
		loadMostPopularTags(first, callback) {
			TagsStore.loadMostPopularTags(first, callback);
		},
		getMostPopularTags() {
			return TagsStore.getMostPopularTags();
		},
		addMostPopularDataChangeListener(callback) {
			TagsStore.addMostPopularDataChangeListener(callback);
		},
		removeMostPopularDataChangeListener(callback) {
			TagsStore.addMostPopularDataChangeListener(callback);
		},
	},

	Vxql: {
		getGuestInitData:                                   function(callback) {
			VxqlStore.getGuestInitData(callback);
		},
		loadLateNightShow:                                  function(callback) {
			VxqlStore.getLateNightShow(callback);
		},
		getCurrentProgram:                                  function(premium, callback) {
			VxqlStore.getCurrentProgram(premium, callback);
		},
		getCamShows:                                        function({endDateFrom, order, startDateTo, first, internal, callback}) {
			VxqlStore.getCamShows({endDateFrom, order, startDateTo, first, internal, callback});
		},
		getUnreadMessagesCount:                             function(callback) {
			VxqlStore.getUnreadMessagesCount(callback);
		},
		getBoughtGiftsForGuest:                             function(callback) {
			VxqlStore.getBoughtGiftsForGuest(callback);
		},
		getMessengerGifts:                                  function(callback) {
			VxqlStore.getMessengerGifts(callback);
		},
		getMessengerToyControls:                            function(callback) {
			VxqlStore.getMessengerToyControls(callback);
		},
		getOnlineModels:                                    function(amount, callback) {
			VxqlStore.getOnlineModels(amount, callback);
		},
		getFilteredModels:                                  function(amount, filter, callback) {
			VxqlStore.getFilteredModels(amount, filter, callback);
		},
		getModels:                                          function(first, last, offset, order, filter, preset, isV3, onlineFilter) {
			return VxqlStore.getModels(first, last, offset, order, filter, preset, isV3, onlineFilter);
		},
		getFilteredVideos:                                  function(amount, filter, callback, order, modelIds, ids) {
			VxqlStore.getFilteredVideos(amount, filter, callback, order, modelIds, ids);
		},
		processGlobalSearch:                                function(searchPattern, isExtended, callback) {
			VxqlStore.processGlobalSearch(searchPattern, isExtended, callback);
		},
		storeSearchPattern:                                 function(searchPattern, callback) {
			VxqlStore.storeSearchPattern(searchPattern, callback);
		},
		getDailyBounes:                                     function(callback) {
			VxqlStore.getDailyBounes(callback);
		},
		getBonusModels:                                     function(callback) {
			VxqlStore.getBonusModels(callback);
		},
		getConsumedOffers:                                  function(callback, first = 31, offset = 0, dateFrom = null, dateTo = null) {
			VxqlStore.getConsumedOffers(callback, first, offset, dateFrom, dateTo);
		},
		sendFeatureFeedback:                                function(featureId, rating, text, url, callback) {
			VxqlStore.sendFeatureFeedback(featureId, rating, text, url, callback);
		},
		addTagToAlbum:                                      function(albumId, tags, callback) {
			VxqlStore.addTagToAlbum(albumId, tags, callback);
		},
		processActorSearch:                                 function(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
			VxqlStore.processActorSearch(searchPattern, callback, first, offset, order, filter);
		},
		processVideoSearch:                                 function(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
			VxqlStore.processVideoSearch(searchPattern, callback, first, offset, order, filter);
		},
		processGallerySearch:                               function(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
			VxqlStore.processGallerySearch(searchPattern, callback, first, offset, order, filter);
		},
		processMediathekSearch:                             function(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
			VxqlStore.processMediathekSearch(searchPattern, callback, first, offset, order, filter);
		},
		processBlogSearch:                                  function(searchPattern, callback, first = 30, offset = 0, order = null, filter = null) {
			VxqlStore.processBlogSearch(searchPattern, callback, first, offset, order, filter);
		},
		getFilteredActorVideos:                             function(
			actorId,
			count,
			offset,
			orders,
			includeGuestRating,
			tags,
			onlyFreeContent,
			fetchAllVideosCount,
			onlyVXSelectContent,
			onlyClassicContent
		) {
			return VxqlStore.getFilteredActorVideos(actorId, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent, onlyClassicContent);
		},
		getCollection:                                      function(order, first, callback) {
			return VxqlStore.getCollection(order, first, callback);
		},
		getActorsByCollectionSlug:                          async function(collectionSlugs, first = 15, offset = 0, filter) {
			return VxqlStore.getActorsByCollectionSlug(collectionSlugs, first, offset, filter);
		},
		getActorsTotalByCollectionSlug:                     async function(collectionSlugs, first = 15, offset = 0, filter) {
			return VxqlStore.getActorsTotalByCollectionSlug(collectionSlugs, first, offset, filter);
		},
		getVideosFromVXQL:                                  function(filter, order, count, offset) {
			return VxqlStore.getVideosFromVXQL(filter, order, count, offset);
		},
		getPhotoAlbumsFromVXQL:                                  function(filter, order, count, offset) {
			return VxqlStore.getPhotoAlbumsFromVXQL(filter, order, count, offset);
		},
		getTVSeriesFromVXQL:                                function(count, offset) {
			return VxqlStore.getTVSeriesFromVXQL(count, offset);
		},
		getTVMediathekVideoRecommendationsByHashIdFromVXQL: function(hashId, count) {
			return VxqlStore.getTVMediathekVideoRecommendationsByHashIdFromVXQL(hashId, count);
		},
		getMediathekVideosFromVXQL:                         function(filter, count, offset) {
			return VxqlStore.getMediathekVideosFromVXQL(filter, count, offset);
		},
		getActorVideoTags:                                  function(actorId) {
			return VxqlStore.getActorVideoTags(actorId);
		},
		getVideoRecommendationsByActorId:                   function(actorId, countFromSameActor, countFromDifferentActor) {
			return VxqlStore.getVideoRecommendationsByActorId(actorId, countFromSameActor, countFromDifferentActor);
		},
		getVideoRecommendationsByAlbumId:                   function(albumId, countFromSameActor, countFromDifferentActor) {
			return VxqlStore.getVideoRecommendationsByAlbumId(albumId, countFromSameActor, countFromDifferentActor);
		},
		getConversionChatActors:                            function(first = 30, offset = 0) {
			return VxqlStore.getConversionChatActors(first, offset);
		},

		/**
		 * @param {Number} actorId
		 * @param {Function} callback
		 */
		loadHeatMaps: function(actorId, callback) {
			VxqlStore.getActorHeatMaps(actorId).then((result) => {
				const chainIsValid    = result && result.data && result.data.model && result.data.model.heatMaps;
				const heatMap         = chainIsValid ? result.data.model.heatMaps.heatMap : [];
				const hoursToBeOnline = chainIsValid ? result.data.model.heatMaps.hoursToBeOnline : null;
				const actorName       = chainIsValid ? result.data.model.actorName : '';
				const memo            = chainIsValid ? result.data.model.heatMaps.memo : null;
				const errors          = result && result.errors ? result.errors : [];
				ActorStore.storeActorHeatMaps(actorId, heatMap, hoursToBeOnline, actorName, memo, errors);

				callback(actorId); // Notify client that it's time to go for a cache into ActorStore

				return result;
			});
		},

		addLiveReaction: function(callback, referenceType, referenceId, imageId, message, data) {
			VxqlStore.addLiveReaction(callback, referenceType, referenceId, imageId, message, data);
		},

		loadVip30ValueHistory: function(callback) {
			VxqlStore.getVip30ValueHistory(callback);
		},

		startAnonymousVoicecallChat: function(callback, actorId, accessToken) {
			VxqlStore.startAnonymousVoicecallChat(callback, actorId, accessToken);
		},

		getVideoStars: function(first = 100) {
			return VxqlStore.getVideoStars(first);
		},

		claimActorGiftCalendarReward: function(actorId, qmDay) {
			return VxqlStore.claimActorGiftCalendarReward(actorId, qmDay);
		},

		touchChannel: function(actorId) {
			VxqlStore.touchChannel(actorId);
		},

		getWelcomeClips: function(first, fsk, callback) {
			VxqlStore.getWelcomeClips(first, fsk, callback);
		},

		buyChatTicket: function(ticket, creationType, callback) {
			VxqlStore.buyChatTicket(ticket, creationType, callback);
		},

        removeChatTicket: function(ticket, callback) {
			VxqlStore.removeChatTicket(ticket, callback);
		},

		getChannels: function(callback, blocked) {
			VxqlStore.getChannels(callback, blocked);
		},

		getPreferencedModel: function(filter, callback) {
			VxqlStore.getPreferencedModel(filter, callback);
		}
	},

	DailyBonus: {
		/**
		 * @param {string} date
		 */
		getDailybonusFromStore: function(date) {
			return VxqlStore.getDailybonusFromStore(date);
		},
		/**
		 * @param {Function} callback
		 */
		addLoadedListener: function(callback) {
			VxqlStore.addLoadedListener(callback);
		},
		/**
		 * @param {String} date
		 * @param {Function} callback
		 */
		getDailyBounesFromApi: function(date, callback) {
			VxqlStore.getDailyBounesFromApi(date, callback);
		},
		/**
		 * @param {Function} callback
		 */
		removeLoadedListener: function(callback) {
			VxqlStore.removeLoadedListener(callback);
		},
		triggerLoad() {
			DailyBonusActionCreator.reload();
		},
		/**
		 * @param {Number} limit
		 * @param {String} order
		 * @return {Array}
		 */
		getInteractiveContent: (limit, order) => DailyBonusStore.getInteractiveContent(limit, order),
		/**
		 * @param {Function} callback
		 */
		addInteractiveContentLoadListener: (callback) => DailyBonusStore.addInteractiveContentLoadListener(callback),
		/**
		 * @param {Function} callback
		 */
		removeInteractiveContentLoadListener: (callback) => DailyBonusStore.removeInteractiveContentLoadListener(callback),
		/**
		 * @param {Number} limit
		 * @param {String} order
		 * @return {void}
		 */
		loadInteractiveContent: (limit, order) => DailyBonusActionCreator.loadInteractiveContent(limit, order),
		/**
		 * @param {Function} callback
		 */
		addEventConfigLoadedListener: (callback) => DailyBonusStore.addEventConfigLoadedListener(callback),
		/**
		 * @param {Function} callback
		 */
		removeEventConfigLoadedListener: (callback) => DailyBonusStore.removeEventConfigLoadedListener(callback),
		/**
		 * @param {String} date
		 * @param {Boolean} isMobile
		 * @return {*}
		 */
		getEventConfig: (date = null, isMobile = false) => DailyBonusStore.getEventConfig(date, isMobile),
		/**
		 * @param {String} date
		 * @param {Boolean} isMobile
		 * @return {void}
		 */
		requestEventConfig: (date, isMobile = false) => DailyBonusActionCreator.requestEventConfig(date, isMobile),
	},

	Actor: {
		getActorData: function(actorId) {
			return ActorStore.getActorData(actorId);
		},

		getActorDataByName: function(actorName) {
			return ActorStore.getActorDataByName(actorName);
		},

		getActorDataNoResult: function() {
			return ActorStore.getActorDataNoResult();
		},

		getActorNote: function(actorId, storeAutoOpenSetting = false) {
			if (storeAutoOpenSetting && actorId) {
				BrowserStore.setLocalItem('NoteactorId', actorId);
			}
			return ActorStore.getActorNote(actorId);
		},

		getActorPictures: function(actorId) {
			return ActorStore.getActorPictures(actorId);
		},

		getActorPicturesByName: function(actorName, limit = -1, fsk = 'none') {
			return ActorStore.getActorPicturesByName(actorName, limit, fsk);
		},

		getActorPromotedVideo: function(actorId) {
			return ActorStore.getActorPromotedVideo(actorId);
		},

		getFilteredActorVideos: function(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent) {
			return ActorStore.getFilteredActorVideos(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent);
		},

		getFilteredActorVideosTotalCount: function(
			actorId,
			sortOrder,
			tags,
			onlyFreeContent,
			includeGuestRating,
			onlyVXSelectContent,
			onlyClassicContent
		) {
			return ActorStore.getFilteredActorVideosTotalCount(actorId, sortOrder, tags, onlyFreeContent, includeGuestRating, onlyVXSelectContent, onlyClassicContent);
		},

		getActorVideoCount: function(actorId) {
			return ActorStore.getActorVideoCount(actorId);
		},

		getActorVideoTagsByActorId: function(actorId) {
			return ActorStore.getActorVideoTagsByActorId(actorId);
		},

		getVideoRecommendationsByActorId: function(actorId, sameActor) {
			return ActorStore.getVideoRecommendationsByActorId(actorId, sameActor);
		},

		getVideoRecommendationsByAlbumId: function(albumId, sameActor) {
			return ActorStore.getVideoRecommendationsByAlbumId(albumId, sameActor);
		},

		/**
		 * @param {Number} actorId
		 * @param {String} ratingType
		 * @returns {Object}
		 */
		getActorChatRatings: function(actorId, ratingType) {
			return ActorStore.getActorChatRatings(actorId, ratingType);
		},

		/**
		 * @param {Number} actorId
		 * @returns {Object|null}
		 */
		getActorHeatMaps: function(actorId) {
			return ActorStore.getActorHeatMaps(actorId);
		},

		/**
		 * @param {Number} actorId
		 * @param {Array} heatMap
		 * @param {Number|Null} hoursToBeOnline
		 * @param {string} actorName
		 * @param {String|Null} memo
		 * @param {Array} errors
		 */
		storeActorHeatMaps: function(actorId, heatMap, hoursToBeOnline, actorName, memo, errors) {
			ActorStore.storeActorHeatMaps(actorId, heatMap, hoursToBeOnline, actorName, memo, errors);
		},

		/**
		 * @param {Number} actorId
		 * @param {String} ratingType
		 * @return {*|boolean}
		 */
		shouldShowRating: function(actorId, ratingType) {
			return ActorStore.shouldShowRating(actorId, ratingType);
		},

		getActorTopVideosByName: function(actorName) {
			return ActorStore.getActorTopVideosByName(actorName);
		},

		getPreliminaryActorData: function(actorName) {
			return ActorStore.getPreliminaryActorData(actorName);
		},

		getProfilePage: function() {
			return ActorStore.getProfilePage();
		},

		setActorPromotedVideoState: function(actorId, state) {
			return ActorStore.setActorPromotedVideoState(actorId, state);
		},

		setActorSedCardsPinnedState: function(actorId, state) {
			return ActorStore.setActorSedCardsPinnedState(actorId, state);
		},

		addActorOnlineStateChangeListener: function(callback) {
			ActorStore.addActorOnlineStateChangeListener(callback);
		},

		removeActorOnlineStateChangeListener: function(callback) {
			ActorStore.removeActorOnlineStateChangeListener(callback);
		},

		emitActorOnlineStateChange: function(actorId, isOnline) {
			ActorStore.emitActorOnlineStateChange(actorId, isOnline);
		},

		loadActorData: function(actorId) {
			ActorActionCreator.loadActorData(actorId);
		},

		loadActorDataByName: function(actorName) {
			ActorActionCreator.loadActorDataByName(actorName);
		},

		getActorSeoContent: function(actorId, tagGroup) {
			return ActorStore.getActorSeoContent(actorId, tagGroup);
		},

		loadActorSeoContent: function(actorId, tagGroup) {
			return ActorActionCreator.loadActorSeoContent(actorId, tagGroup);
		},

		/**
		 * @param {String|Number} actorId
		 * @param {Number} limit
		 * @param {String|Number} fsk
		 */
		loadActorPictures: function(actorId, limit = -1, fsk = 'none') {
			ActorActionCreator.loadActorPictures(actorId, limit, fsk);
		},

		loadFilteredActorVideos: function(
			actorId,
			count,
			offset,
			orders,
			includeGuestRating,
			tags,
			onlyFreeContent,
			fetchAllVideosCount,
			onlyVXSelectContent,
			onlyClassicContent
		) {
			ActorActionCreator.loadFilteredActorVideos(actorId, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent, onlyClassicContent);
		},

		loadFilteredActorsVideos: function(
			actorIds,
			count,
			offset,
			orders,
			includeGuestRating,
			tags,
			onlyFreeContent,
			fetchAllVideosCount,
			onlyVXSelectContent
		) {
			ActorActionCreator.loadFilteredActorsVideos(actorIds, count, offset, orders, includeGuestRating, tags, onlyFreeContent, fetchAllVideosCount, onlyVXSelectContent);
		},

		loadActorVideoTags: function(actorId) {
			ActorActionCreator.loadActorVideoTags(actorId);
		},

		loadVideoRecommendationsByActorId: function(actorId, countFromSameActor, countFromDifferentActor) {
			ActorActionCreator.loadVideoRecommendationsByActorId(actorId, countFromSameActor, countFromDifferentActor);
		},

		loadVideoRecommendationsByAlbumId: function(albumId, countFromSameActor, countFromDifferentActor) {
			ActorActionCreator.loadVideoRecommendationsByAlbumId(albumId, countFromSameActor, countFromDifferentActor);
		},

		/** @deprecated */
		loadActorTopVideos: function(actorId) {
			ActorActionCreator.loadActorTopVideos(actorId);
		},

		loadActorPromotedVideo: function(actorId) {
			ActorActionCreator.loadActorPromotedVideo(actorId);
		},

		loadActorState: function(actorId) {
			ActorActionCreator.loadActorState(actorId);
		},

		loadActorNote: function(actorId) {
			ActorActionCreator.loadActorNote(actorId);
		},

		/**
		 * @param {Number} actorId
		 */
		loadChatRatings: function(actorId) {
			ActorActionCreator.loadActorChatRatings(actorId);
		},

		storeActorNote: function(actorId, comment) {
			ActorStore.storeActorNote(actorId, comment);
		},

		setPreliminaryData: function(actorData) {
			ActorActionCreator.setPreliminaryData(actorData);
		},

		setProfilePage: function(pageId) {
			ActorActionCreator.setProfilePage(pageId);
		},

		addActorDataChangeListener: function(callback) {
			ActorStore.addActorDataChangeListener(callback);
		},

		removeActorDataChangeListener: function(callback) {
			ActorStore.removeActorDataChangeListener(callback);
		},

		addActorDataNoResultListener: function(callback) {
			ActorStore.addActorDataNoResultListener(callback);
		},

		removeActorDataNoResultListener: function(callback) {
			ActorStore.removeActorDataNoResultListener(callback);
		},

		addActorPicturesChangeListener: function(callback) {
			ActorStore.addActorPicturesChangeListener(callback);
		},

		removeActorPicturesChangeListener: function(callback) {
			ActorStore.removeActorPicturesChangeListener(callback);
		},

		addActorVideosChangeListener: function(callback) {
			ActorStore.addActorVideosChangeListener(callback);
		},

		removeActorVideosChangeListener: function(callback) {
			ActorStore.removeActorVideosChangeListener(callback);
		},

		addActorVideoTagsChangeListener: function(callback) {
			ActorStore.addActorVideoTagsChangeListener(callback);
		},

		removeActorVideoTagsChangeListener: function(callback) {
			ActorStore.removeActorVideoTagsChangeListener(callback);
		},

		addVideoRecommendationsByActorIdChangeListener: function(callback) {
			ActorStore.addVideoRecommendationsByActorIdChangeListener(callback);
		},

		removeVideoRecommendationsByActorIdChangeListener: function(callback) {
			ActorStore.removeVideoRecommendationsByActorIdChangeListener(callback);
		},

		addVideoRecommendationsByAlbumIdChangeListener: function(callback) {
			ActorStore.addVideoRecommendationsByAlbumIdChangeListener(callback);
		},

		removeVideoRecommendationsByAlbumIdChangeListener: function(callback) {
			ActorStore.removeVideoRecommendationsByAlbumIdChangeListener(callback);
		},

		addActorPromotedVideoChangeListener: function(callback) {
			ActorStore.addActorPromotedVideoChangeListener(callback);
		},

		removeActorPromotedVideoChangeListener: function(callback) {
			ActorStore.removeActorPromotedVideoChangeListener(callback);
		},

		addActorTopVideosChangeListener: function(callback) {
			ActorStore.addActorTopVideosChangeListener(callback);
		},

		removeActorTopVideosChangeListener: function(callback) {
			ActorStore.removeActorTopVideosChangeListener(callback);
		},

		addProfilePageChangeListener: function(callback) {
			ActorStore.addProfilePageChangeListener(callback);
		},

		removeProfilePageChangeListener: function(callback) {
			ActorStore.removeProfilePageChangeListener(callback);
		},

		addActorNoteChangeListener: function(callback) {
			ActorStore.addActorNoteChangeListener(callback);
		},

		removeActorNoteChangeListener: function(callback) {
			ActorStore.removeActorNoteChangeListener(callback);
		},

		/**
		 * @param {Function} callback
		 */
		addActorChatRatingsChangeListener: function(callback) {
			ActorStore.addActorChatRatingsChangeListener(callback);
		},

		/**
		 * @param {Function} callback
		 */
		removeActorChatRatingsChangeListener: function(callback) {
			ActorStore.removeActorChatRatingsChangeListener(callback);
		},

		/**
		 * @param {Function} callback
		 * @return {*|void}
		 */
		addInterviewsChangeListener: function(callback) {
			ActorStore.addInterviewsChangeListener(callback);
		},

		/**
		 * @param {Function} callback
		 * @return {*|void}
		 */
		removeInterviewsChangeListener: function(callback) {
			ActorStore.removeInterviewsChangeListener(callback);
		},

		/**
		 * @param {Number} actorId
		 * @param {String} imageSize
		 * @param {Boolean} placeholder
		 * @param {String} durationFormat
		 * @return {Array|null}
		 */
		getActorInterviews: (
			                    actorId,
			                    imageSize      = Constants.Interviews.IMAGE_SIZE_720,
			                    placeholder    = true,
			                    durationFormat = Constants.Interviews.DURATION_SEC
		                    ) => ActorStore.getActorInterviews(actorId, imageSize, placeholder, durationFormat),

		/**
		 * @param {Number} actorId
		 * @param {String} imageSize
		 * @param {Boolean} placeholder
		 * @param {String} durationFormat
		 * @return {void}
		 */
		loadActorInterviews: (
			                     actorId,
			                     imageSize      = Constants.Interviews.IMAGE_SIZE_720,
			                     placeholder    = true,
			                     durationFormat = Constants.Interviews.DURATION_SEC
		                     ) => ActorActionCreator.loadActorInterviews(actorId, imageSize, placeholder, durationFormat),

		/**
		 * @param {Number} actorId
		 * @param {Number} first
		 * @return {array|null}
		 */
		getModelRecommendations: (actorId, first = 4) => ActorStore.getModelRecommendations(actorId, first),

		/**
		 * @param {Number} actorId
		 * @param {Number} first
		 * @return {void}
		 */
		loadModelRecommendations: (actorId, first = 4, isExtended = false) => ActorActionCreator.loadModelRecommendations(actorId, first, isExtended),

		/**
		 * @param {Function} callback
		 * @return {void}
		 */
		addModelRecommendationsChangeListener: (callback) => ActorStore.addModelRecommendationsChangeListener(callback),

		/**
		 * @param {Function} callback
		 * @return {void}
		 */
		removeModelRecommendationsChangeListener: (callback) => ActorStore.removeModelRecommendationsChangeListener(callback),

		/**
		 * @param {Number} actorId
		 * @param {Function} callback
		 * @return {void}
		 */
		getProfileLiveShowTicket: (actorId, callback) => VxqlStore.getProfileLiveShowTicket(actorId, callback),

		addActorSeoContentChangeListener: function(callback) {
			ActorStore.addActorSeoContentChangeListener(callback);
		},

		removeActorSeoContentChangeListener: function(callback) {
			ActorStore.removeActorSeoContentChangeListener(callback);
		},
	},

	VideoV2: {
		addVideoDataChangeListener: function(callback, id) {
			VideoV2Store.addVideoDataChangeListener(callback, id);
		},

		removeVideoDataChangeListener: function(callback) {
			VideoV2Store.removeVideoDataChangeListener(callback);
		},

		addVideoDataLoadListener: function(callback) {
			VideoV2Store.addVideoDataLoadListener(callback);
		},

		removeVideoDataLoadListener: function(callback) {
			VideoV2Store.removeVideoDataLoadListener(callback);
		},

		getDefaultFilterForCategory: function(category) {
			return VideoV2Store.getDefaultFilterForCategory(category);
		},

		getDefaultOrderForCategory: function(category) {
			return VideoV2Store.getDefaultOrderForCategory(category);
		},

		getVideos: function(filter, order) {
			return VideoV2Store.getVideos(filter, order);
		},

		getVideoTotalCount: function(filter, order) {
			return VideoV2Store.getVideoTotalCount(filter, order);
		},

		isVideoDataLoading: function(filter, order) {
			return VideoV2Store.isVideoDataLoading(filter, order);
		},

		loadVideos: function(filter, order, count, offset = 0, id = null) {
			ActionCreator.VideoV2.loadVideos(filter, order, count, offset, id);
		},
	},

	Galleries: {
		getGalleries: function(filter, sort) {
			return GalleriesStore.getGalleries(filter, sort);
		},

		getGalleriesTotal: function(filter, sort) {
			return GalleriesStore.getGalleriesTotal(filter, sort);
		},

		isGalleriesDataLoading: function(filter, sort) {
			return GalleriesStore.isGalleriesDataLoading(filter, sort);
		},

		addGalleriesDataChangeListener: function(callback) {
			GalleriesStore.addGalleriesDataChangeListener(callback);
		},

		removeGalleriesDataChangeListener: function(callback) {
			GalleriesStore.removeGalleriesDataChangeListener(callback);
		},

		addGalleriesDataLoadListener: function(callback) {
			GalleriesStore.addGalleriesDataLoadListener(callback);
		},

		removeGalleriesDataLoadListener: function(callback) {
			GalleriesStore.removeGalleriesDataLoadListener(callback);
		},

		loadGalleries: function(filter, sort, count, offset = 0, id, ignoreLoading = false) {
			ActionCreator.Galleries.loadGalleries(filter, sort, count, offset, id, ignoreLoading);
		},
	},

	Actors: {
		addActorsDataChangeListener: function(callback) {
			ActorsStore.addActorsDataChangeListener(callback);
		},

		removeActorsDataChangeListener: function(callback) {
			ActorsStore.removeActorsDataChangeListener(callback);
		},

		addActorsDataLoadListener: function(callback) {
			ActorsStore.addActorsDataLoadListener(callback);
		},

		removeActorsDataLoadListener: function(callback) {
			ActorsStore.removeActorsDataLoadListener(callback);
		},

		getActors: function(filter, order, preset, id) {
			return ActorsStore.getActors(filter, order, preset, id);
		},

		getActorsTotalCount: function(filter, order, preset) {
			return ActorsStore.getActorsTotalCount(filter, order, preset);
		},

		isActorsDataLoading: function(filter, order, preset) {
			return ActorsStore.isActorsDataLoading(filter, order, preset);
		},

		loadActors: function(first, last, offset, order, filter, preset, id, isV3, onlineFilter) {
			ActionCreator.Actors.loadActors(first, last, offset, order, filter, preset, id, isV3, onlineFilter);
		},
	},

	TVSeries: {
		addTVSeriesDataChangeListener: function(callback) {
			TVSeriesStore.addTVSeriesDataChangeListener(callback);
		},

		removeTVSeriesDataChangeListener: function(callback) {
			TVSeriesStore.removeTVSeriesDataChangeListener(callback);
		},

		addTVSeriesLoadListener: function(callback) {
			TVSeriesStore.addTVSeriesLoadListener(callback);
		},

		removeTVSeriesLoadListener: function(callback) {
			TVSeriesStore.removeTVSeriesLoadListener(callback);
		},

		getTVSeries: function() {
			return TVSeriesStore.getSeries();
		},

		getTVSeriesTotalCount: function() {
			return TVSeriesStore.getSeriesTotalCount();
		},

		isTVSeriesLoading: function() {
			return TVSeriesStore.isTVSeriesLoading();
		},

		loadTVSeries: function(count, offset = 0) {
			ActionCreator.TVSeries.loadTVSeries(count, offset);
		},
	},

	Favorite: {

		setPinnedActorEmailNotification: function(actorId, enabled) {
			ActionCreator.Notification.setPinnedActorEmailNotification(actorId, enabled);
		},

		setPinnedActorSMSNotification: function(actorId, enabled) {
			ActionCreator.Notification.setPinnedActorSMSNotification(actorId, enabled);
		},

		getOnlineCount: function() {
			return FavoriteStore.getFavoriteActorsOnlineCount();
		},

		getActors: function() {
			return FavoriteStore.getFavoriteActors();
		},

		addActorsChangeListener: function(callback) {
			FavoriteStore.addFavoriteActorsChangeListener(callback);
		},

		removeActorsChangeListener: function(callback) {
			FavoriteStore.removeFavoriteActorsChangeListener(callback);
		},

		addActorPinnedListener: function(callback) {
			FavoriteStore.addActorPinnedListener(callback);
		},

		removeActorPinnedListener: function(callback) {
			FavoriteStore.removeActorPinnedListener(callback);
		},

		addActorUnpinnedListener: function(callback) {
			FavoriteStore.addActorUnpinnedListener(callback);
		},

		removeActorUnpinnedListener: function(callback) {
			FavoriteStore.removeActorUnpinnedListener(callback);
		},

		pinActor: function(actorId) {
			ActionCreator.Notification.pinActor(actorId);
		},

		unpinActor: function(actorId) {
			ActionCreator.Notification.unpinActor(actorId);
		},

		pinAlbum: function(albumId) {
			ActionCreator.Notification.pinAlbum(albumId);
		},

		unpinAlbum: function(albumId) {
			ActionCreator.Notification.unpinAlbum(albumId);
		},

		pinSedcards: function(actorId) {
			ActionCreator.Notification.pinSedcards(actorId);
		},

		unpinSedcards: function(actorId) {
			ActionCreator.Notification.unpinSedcards(actorId);
		},

		addAlbumPinStatusListener: function(callback) {
			FavoriteStore.addAlbumPinStatusListener(callback);
		},

		removeAlbumPinStatusListener: function(callback) {
			FavoriteStore.removeAlbumPinStatusListener(callback);
		},

		addSedcardsAlbumPinStatusListener: function(callback) {
			FavoriteStore.addSedcardsAlbumPinStatusListener(callback);
		},

		removeSedcardsAlbumPinStatusListener: function(callback) {
			FavoriteStore.removeSedcardsAlbumPinStatusListener(callback);
		},

		pinPicture: function(pictureId) {
			ActionCreator.Notification.pinPicture(pictureId);
		},

		unpinPicture: function(pictureId) {
			ActionCreator.Notification.unpinPicture(pictureId);
		},

		addPicturePinStatusListener: function(callback) {
			FavoriteStore.addPicturePinStatusListener(callback);
		},

		removePicturePinStatusListener: function(callback) {
			FavoriteStore.removePicturePinStatusListener(callback);
		},

		addNotifyEmailListener: function(callback) {
			FavoriteStore.addNotifyEmailListener(callback);
		},

		removeNotifyEmailListener: function(callback) {
			FavoriteStore.removeNotifyEmailListener(callback);
		},

	},

	Guest: {

		init: function() {
			GuestStore.init();
		},

		updateGuestData: function() {
			GuestStore.updateGuestData();
		},

		setIsVoicecallActive: function(isVoicecallActive) {
			ActionCreator.Guest.setIsVoicecallActive(isVoicecallActive);
		},

		isVoicecallActive: function() {
			return GuestStore.isVoicecallActive();
		},

		addAutoRechargeTeaserStateChangeListener: function(callback) {
			GuestStore.addAutoRechargeTeaserStateChangeListener(callback);
		},

		removeAutoRechargeTeaserStateChangeListener: function(callback) {
			GuestStore.removeAutoRechargeTeaserStateChangeListener(callback);
		},

		addCanOneClickChangeListener: function(callback) {
			GuestStore.addCanOneClickChangeListener(callback);
		},

		removeCanOneClickChangeListener: function(callback) {
			GuestStore.removeCanOneClickChangeListener(callback);
		},

		requestGuestBalance: function() {
			ActionCreator.Guest.requestGuestBalance();
		},

		setAutoRechargeTeaserState: function(mode) {
			ActionCreator.Guest.setAutoRechargeTeaserState(mode);
		},

		setBalanceFormatted: function(guestBalance, guestBalanceFormatted, isPayingCustomer) {
			ActionCreator.Guest.setBalanceFormatted(guestBalance, guestBalanceFormatted, isPayingCustomer);
		},

		setVouchers: function(vouchers) {
			ActionCreator.Guest.setVouchers(vouchers);
		},

		setHasAvs: function(hasAvs) {
			ActionCreator.Guest.setHasAvs(hasAvs);
		},

		setAvsStatus: function(avsStatus) {
			ActionCreator.Guest.setAvsStatus(avsStatus);
		},

		setAvsRejectMessage: function(rejectMessage) {
			ActionCreator.Guest.setAvsRejectMessage(rejectMessage);
		},

		setHasWelcomeBonusRedeemed: function() {
			ActionCreator.Guest.setHasWelcomeBonusRedeemed();
		},

		setIsLocked: function(isLocked) {
			ActionCreator.Guest.setIsLocked(isLocked);
		},

		setIsVip: function(isVip) {
			ActionCreator.Guest.setIsVip(isVip);
		},

		setIsVXSelect: function(isVXSelect) {
			ActionCreator.Guest.setIsVXSelect(isVXSelect);
		},

		setIsVXTVAboSubscriber: function(isVXTVAboSubscriber) {
			ActionCreator.Guest.setIsVXTVAboSubscriber(isVXTVAboSubscriber);
		},

		setCanOneClick: function(oneClickConfig, oneClickAmounts, autoRechargeConfig, autoRechargeAmount, isPaying) {
			ActionCreator.Guest.setCanOneClick(oneClickConfig, oneClickAmounts, autoRechargeConfig, autoRechargeAmount, isPaying);
		},

		setShowDataProtection: function(showDataProtection) {
			ActionCreator.Guest.setShowDataProtection(showDataProtection);
		},

		subscribeNewsletter: function(typeId, userId = null, emailAddress = null) {
			ActionCreator.Guest.subscribeNewsletter(typeId, userId, emailAddress);
		},

		addNewsletterSubscribedListener: function(callback) {
			GuestStore.addNewsletterSubscribedListener(callback);
		},

		removeNewsletterSubscribedListener: function(callback) {
			GuestStore.removeNewsletterSubscribedListener(callback);
		},

		unsubscribeNewsletter: function(userId, emailAddress, option, reasons) {
			ActionCreator.Guest.unsubscribeNewsletter(userId, emailAddress, option, reasons);
		},

		addNewsletterUnsubscribedListener: function(callback) {
			GuestStore.addNewsletterUnsubscribedListener(callback);
		},

		removeNewsletterUnsubscribedListener: function(callback) {
			GuestStore.removeNewsletterUnsubscribedListener(callback);
		},

		getVoucherFromParam: function() {
			return GuestStore.getVoucherFromParam();
		},

		getBoughtGifts: function() {
			return GuestStore.getBoughtGifts();
		},

		getNotificationSettings: function() {
			return GuestStore.getNotificationSettings();
		},

		getBalanceFormatted: function() {
			return GuestStore.getBalanceFormatted();
		},

		getBalance: function() {
			return GuestStore.getBalance();
		},

		getEmailAddress: function() {
			return GuestStore.getEmailAddress();
		},

		getName: function() {
			return GuestStore.getName();
		},

		getId: function() {
			return GuestStore.getId();
		},

		addChangeListener: function(callback) {
			GuestStore.addGuestChangeListener(callback);
		},

		removeChangeListener: function(callback) {
			GuestStore.removeGuestChangeListener(callback);
		},

		canAutoRecharge: function() {
			return GuestStore.canAutoRecharge();
		},

		canOneClick: function() {
			return GuestStore.canOneClick();
		},

		getAutoRechargeAmount: function() {
			return GuestStore.getAutoRechargeAmount();
		},

		getAutoRechargeConfig: function(kind, hostId) {
			return GuestStore.getAutoRechargeConfig(kind, hostId);
		},

		getOneClickAmounts: function() {
			return GuestStore.getOneClickAmounts();
		},

        getOneClickChargeAmounts: function() {
			return GuestStore.getOneClickChargeAmounts();
		},

		getPreselectedOneClickAmount: function(expectedAmount = 0) {
			const amounts = GuestStore.getOneClickAmounts();

			let retVal = 0;
			if (amounts && amounts.length > 0) {
				retVal = amounts.reduce((acc, item) => {
					const origDiff = Math.abs(acc - expectedAmount);
					const currDiff = Math.abs(item - expectedAmount);

					if (currDiff < origDiff) {
						acc = item;
					}

					return acc;
				}, Math.max.apply(null, amounts));
			}

			return retVal;
		},

		getOneClickConfig: function(kind, hostId) {
			return GuestStore.getOneClickConfig(kind, hostId);
		},

		getNotificationsSettings: function(onSuccess, onFail) {
			return ActionCreator.Guest.getNotificationsSettings(onSuccess, onFail);
		},

		hasAvs: function() {
			return GuestStore.hasAvs();
		},

		getAVSStatus: function() {
			return GuestStore.getAVSStatus();
		},

		getAVSRejectMessage: function() {
			return GuestStore.getAVSRejectMessage();
		},

		getTestGroup: function(testId) {
			return GuestStore.getTestGroup(testId);
		},

		isTestActive: function(testId) {
			return GuestStore.isTestActive(testId);
		},

		isAnonymousVoicecall: function() {
			return GuestStore.isAnonymousVoicecall();
		},

		isDataProtectionShown: function() {
			return GuestStore.isDataProtectionShown();
		},

		isLocked: function() {
			return GuestStore.isLocked();
		},

		isLoggedIn: function() {
			return GuestStore.isLoggedIn();
		},

		isPayingCustomer: function() {
			return GuestStore.isPayingCustomer();
		},

		isRestricted: function() {
			return GuestStore.isRestricted();
		},

		isForcePayment: function() {
			return GuestStore.isForcePayment();
		},

		isBlockedForComments: function() {
			return GuestStore.isBlockedForComments();
		},

		isVip: function() {
			return GuestStore.isVip();
		},

		isVXSelect: function() {
			return GuestStore.isVXSelect();
		},

		isPremium: function() {
			return GuestStore.isVXTVAboSubscriber();
		},

		wasPremium: function() {
			return GuestStore.wasVXTVAboSubscriber();
		},

		getSignupOrigin() {
			return GuestStore.getSignupOrigin();
		},

		initGeoLocation() {
			GuestStore.initGeoLocation();
		},

		getGeoLocation: function(parsed = false) {
			return GuestStore.getGeoLocation(parsed);
		},

		getGuestUrlPreviewPicture: function() {
			return GuestStore.getGuestUrlPreviewPicture();
		},

		getVoucherCountByType: function(type, actorId = null) {
			return GuestStore.getVoucherCountByType(type, actorId);
		},

		getPincallVerificationById: function(id) {
			return GuestStore.getPincallVerificationById(id);
		},

		redeemBonus: function(bonusCode) {
			ActionCreator.Guest.redeemBonus(bonusCode);
		},

		addRedeemedBonusListener: function(callback) {
			GuestStore.addRedeemedBonusListener(callback);
		},

		addNotificationsSettingsReceivedListener: function(callback) {
			GuestStore.addNotificationsSettingsReceivedListener(callback);
		},

		removeNotificationsSettingsReceivedListener: function(callback) {
			GuestStore.removeNotificationsSettingsReceivedListener(callback);
		},

		removeRedeemedBonusListener: function(callback) {
			GuestStore.removeRedeemedBonusListener(callback);
		},

		acceptCancellationOffer: function(guestId, platformText, offerActivationFail, offerActivationSuccess) {
			ActionCreator.Guest.acceptCancellationOffer(guestId, platformText, offerActivationFail, offerActivationSuccess);
		},

		closeDataProtection: function(accepted) {
			ActionCreator.Guest.closeDataProtection(accepted);
		},

		closeAgbAndDataProtection: function(accepted) {
			ActionCreator.Guest.closeAgbAndDataProtection(accepted);
		},

		sendVideoShareEmail: function(recipient, message, videoId, guestId) {
			ActionCreator.Guest.sendVideoShareEmail(recipient, message, videoId, guestId);
		},

		addEmailSuccessListener: function(callback) {
			GuestStore.addEmailSuccessListener(callback);
		},

		removeEmailSuccessListener: function(callback) {
			GuestStore.removeEmailSuccessListener(callback);
		},

		addEmailGeneralFailListener: function(callback) {
			GuestStore.addEmailGeneralFailListener(callback);
		},

		removeEmailGeneralFailListener: function(callback) {
			GuestStore.removeEmailGeneralFailListener(callback);
		},

		addEmailTimeoutFailListener: function(callback) {
			GuestStore.addEmailTimeoutFailListener(callback);
		},

		removeEmailTimeoutFailListener: function(callback) {
			GuestStore.removeEmailTimeoutFailListener(callback);
		},

		addGeoLocationChangeListener: function(callback) {
			GuestStore.addGeoLocationChangeListener(callback);
		},

		removeGeoLocationChangeListener: function(callback) {
			GuestStore.removeGeoLocationChangeListener(callback);
		},

		updateBoughtGiftsForGuest: function() {
			GuestStore.updateBoughtGiftsForGuest();
		},

		getActiveFeatureIdsWithFeedback: function() {
			return GuestStore.getActiveFeatureIdsWithFeedback();
		},

		sendFeatureFeedback: function(featureId, rating, text, url) {
			ActionCreator.Guest.sendFeatureFeedback(featureId, rating, text, url);
		},

		addFeatureFeedbackSentListener: function(callback) {
			GuestStore.addFeatureFeedbackSentListener(callback);
		},

		removeFeatureFeedbackSentListener: function(callback) {
			GuestStore.removeFeatureFeedbackSentListener(callback);
		},

		addGuestRelation: function(relationTypeId, objectTypeId, objectId) {
			GuestStore.addGuestRelation(relationTypeId, objectTypeId, objectId);
		},

		hasGuestRelation: function(relationTypeId, objectTypeId, objectId) {
			return GuestStore.hasGuestRelation(relationTypeId, objectTypeId, objectId);
		},

		addAddRelationResultListener: function(callback) {
			GuestStore.addAddRelationResultListener(callback);
		},

		removeAddRelationResultListener: function(callback) {
			GuestStore.removeAddRelationResultListener(callback);
		},

		isEligibleForConversionChat: function() {
			return GuestStore.isEligibleForConversionChat();
		},

		isCandidateForConversionChat: function() {
			return GuestStore.isCandidateForConversionChat();
		},

		updateConversionChat: function(enable) {
			return GuestStore.updateConversionChat(enable);
		},

		addGuestPreferences: function(data, callback) {
			GuestStore.addGuestPreferences(data, callback);
		},
        getGuestPreferences: function() {
            return GuestStore.getGuestPreferences();
        },

		addGuestChangeListener: function(callback) {
			GuestStore.addGuestChangeListener(callback);
		},

		removeGuestChangeListener: function(callback) {
			GuestStore.removeGuestChangeListener(callback);
		},

		addPincallVerificationStartedListener: function(callback) {
			GuestStore.addPincallVerificationStartedListener(callback);
		},

		removePincallVerificationStartedListener: function(callback) {
			GuestStore.removePincallVerificationStartedListener(callback);
		},

		addAvsPayRequestListener: function(callback) {
			GuestStore.addAvsPayRequestListener(callback);
		},

		removeAvsPayRequestListener: function(callback) {
			GuestStore.removeAvsPayRequestListener(callback);
		},

		addAvsBonusListener: function(callback) {
			GuestStore.addAvsBonusListener(callback);
		},

		removeAvsBonusListener: function(callback) {
			GuestStore.removeAvsBonusListener(callback);
		},

		addConversionChatUpdateEvent: function(callback) {
			GuestStore.addConversionChatUpdateEvent(callback);
		},

		removeConversionChatUpdateEvent: function(callback) {
			GuestStore.removeConversionChatUpdateEvent(callback);
		},

		addPincallResultReceivedListener: function(callback) {
			GuestStore.addPincallResultReceivedListener(callback);
		},

		removePincallResultReceivedListener: function(callback) {
			GuestStore.removePincallResultReceivedListener(callback);
		},

		startPincallVerification: function(phoneNumber, verificationType, language) {
			ActionCreator.Guest.startPincallVerification(phoneNumber, verificationType, language);
		},

		verifyPincall: function(id, verificationType, pin) {
			ActionCreator.Guest.verifyPincall(id, verificationType, pin);
		},

		perpareAvsPayRequest() {
			ActionCreator.Guest.perpareAvsPayRequest();
		},

		isAvsBonusAvailable() {
			ActionCreator.Guest.isAvsBonusAvailable();
		},

		addVideoLike: function(id, callback) {
			ActionCreator.Guest.setVideoLike(id);
			VxqlStore.addVideoLike(id, callback);
		},

		addVideoDislike: function(id, callback) {
			ActionCreator.Guest.setVideoDislike(id);
			VxqlStore.addVideoDislike(id, callback);
		},

		addAlbumView: function(id) {
			Flux.Guest.isLoggedIn() && VxqlStore.addAlbumView(id);
		},

		addVideoLikeChangeListener: function(callback) {
			GuestStore.addVideoLikeChangeListener(callback);
		},

		removeVideoLikeChangeListener: function(callback) {
			GuestStore.removeVideoLikeChangeListener(callback);
		},

		addVideoDislikeChangeListener: function(callback) {
			GuestStore.addVideoDislikeChangeListener(callback);
		},

		removeVideoDislikeChangeListener: function(callback) {
			GuestStore.removeVideoDislikeChangeListener(callback);
		},

		addGalleryLike: function(id, callback) {
			ActionCreator.Guest.setGalleryLike(id);
			VxqlStore.addGalleryLike(id, callback);
		},

		addGalleryDislike: function(id, callback) {
			ActionCreator.Guest.setGalleryDislike(id);
			VxqlStore.addGalleryDislike(id, callback);
		},

		addGalleryLikeChangeListener: function(callback) {
			GuestStore.addGaleryLikeChangeListener(callback);
		},

		removeGaleryLikeChangeListener: function(callback) {
			GuestStore.removeGalleryLikeChangeListener(callback);
		},

		addGalleryDislikeChangeListener: function(callback) {
			GuestStore.addGalleryDislikeChangeListener(callback);
		},

		removeGalleryDislikeChangeListener: function(callback) {
			GuestStore.removeGaleryDislikeChangeListener(callback);
		},

		getRestrictionLevel: function() {
			return GuestStore.getRestrictionLevel();
		},

		/**
		 *
		 * @param {function} callback
		 * @param {boolean} feedOnly
		 */
		getFavoriteModels: function(callback, feedOnly = false) {
			return GuestStore.getFavoriteModels(callback, feedOnly);
		},

		/**
		 *
		 * @param first
		 * @param feedOnly
		 * @param callback
		 * @returns {void|*}
		 */
		getRecommendedModels: function(first, feedOnly, callback) {
			return GuestStore.getRecommendedModels(first, feedOnly, callback);
		},

		/**
		 *
		 * @param {function} callback
		 */
		getBenefits: function(callback) {
			return GuestStore.getBenefits(callback);
		},

		addBenefitsCodeListener: function(callback) {
			GuestStore.addBenefitsCodeListener(callback);
		},

		removeBenefitsCodeListener: function(callback) {
			GuestStore.removeBenefitsCodeListener(callback);
		},

		setBenefitsCode: function(code) {
			ActionCreator.Guest.setBenefitsCode(code);
		},

		/**
		 *
		 * @param {string} code
		 * @param {function} callback
		 */
		getBonusCode: function(code, callback) {
			return VxqlStore.getBonusCode(code, callback);
		},

		/**
		 *
		 * @param {function} callback
		 */
		getRemainingBonusCodes: function(callback) {
			return VxqlStore.getRemainingBonusCodes(callback);
		},

		/**
		 *
		 * @param {function} callback
		 */
		getMyVisitXSidebarData: function(callback) {
			return GuestStore.getMyVisitXSidebarData(callback);
		},

		/**
		 *
		 * @param {number} actorId
		 */
		loadFreeChatDuration: function(actorId) {
			GuestStore.loadFreeChatDuration(actorId);
		},

		/**
		 *
		 * @param {number} actorId
		 * @returns {number|null}
		 */
		getFreeChatDuration: function(actorId) {
			return GuestStore.getFreeChatDuration(actorId);
		},

		/**
		 *
		 * @param {function} callback
		 */
		addFreeChatDurationChangeListener: function(callback) {
			GuestStore.addFreeChatDurationChangeListener(callback);
		},


		/**
		 *
		 * @param {function} callback
		 */
		removeFreeChatDurationChangeListener: function(callback) {
			GuestStore.removeFreeChatDurationChangeListener(callback);
		},

		clearFreeChatDurations: function() {
			GuestStore.clearFreeChatDurations();
		},

		updateLoyaltyPoints: function(points) {
			GuestStore.updateLoyaltyPoints(points);
		},

		addLoyaltyPointsChangedListener: function(callback) {
			GuestStore.addLoyaltyPointsChangedListener(callback);
		},

		removeLoyaltyPointsChangedListener: function(callback) {
			GuestStore.removeLoyaltyPointsChangedListener(callback);
		},
		getGuestVideoTime: function(chatId, callback) {
			VxqlStore.getGuestVideoTime(chatId, callback);
		},

        initVxEsFreePreview: function(decline = false) {
            VxqlStore.initVxEsFreePreviewInit(decline);
        },

        addFifteenMinutesPreviewCountdownListener: function(callback) {
            GuestStore.addFifteenMinutesPreviewCountdownListener(callback);
        },
        removeFifteenMinutesPreviewCountdownListener: function(callback) {
            GuestStore.removeFifteenMinutesPreviewCountdownListener(callback);
        },
        fifteenMinutesPreviewCountdownChange: function(isDone) {
            GuestStore.fifteenMinutesPreviewCountdownChange(isDone);
        }
	},

	Promo: {
		addHasNotRedeemedPromoCodeInstanceChangeListener: function(callback) {
			PromoStore.addHasNotRedeemedPromoCodeInstanceChangeListener(callback);
		},

		removeHasNotRedeemedPromoCodeInstanceChangeListener: function(callback) {
			PromoStore.removeHasNotRedeemedPromoCodeInstanceChangeListener(callback);
		},

		hasGuestNotRedeemedOnTopCodeInstanceByName: function(promoCode) {
			ActionCreator.Promo.hasGuestNotRedeemedOnTopCodeInstanceByName(promoCode);
		},
	},

	NewMessenger: {
		startChat:                                 function(chatType, actorId) {
			MessengerStore.startChat(chatType, actorId);
		},
		startAnonymousVoicecallChat:               function(actorId) {
			MessengerStore.startAnonymousVoicecallChat(actorId);
		},
		touchChannel:                              function(actorId) {
			MessengerStore.touchChannel(actorId);
		},
		addStartChatListener:                      function(callback) {
			MessengerStore.addStartChatListener(callback);
		},
		removeStartChatListener:                   function(callback) {
			MessengerStore.removeStartChatListener(callback);
		},
		stopChat:                                  function() {
			MessengerStore.stopChat();
		},
		addStopChatListener:                       function(callback) {
			MessengerStore.addStopChatListener(callback);
		},
		removeStopChatListener:                    function(callback) {
			MessengerStore.removeStopChatListener(callback);
		},
		addChatExitChangeListener:                 function(callback) {
			MessengerStore.addChatExitChangeListener(callback);
		},
		removeChatExitChangeListener:              function(callback) {
			MessengerStore.removeChatExitChangeListener(callback);
		},
		setIsChatExitOpen:                         function(isOpen) {
			ActionCreator.Messenger.setIsChatExitOpen(isOpen);
		},
		isChatExitOpen:                            function() {
			return MessengerStore.isChatExitOpen();
		},
		addChatTicketChangeListener:               function(callback) {
			MessengerStore.addChatTicketChangeListener(callback);
		},
		removeChatTicketChangeListener:            function(callback) {
			MessengerStore.removeChatTicketChangeListener(callback);
		},
		buyChatTicket:                             function(ticket, creationType = Flux.Constants.TicketCreationType.CHAT_TICKET) {
			ActionCreator.Messenger.buyChatTicket(ticket, creationType);
		},
        removeChatTicket:                          function(ticket) {
			ActionCreator.Messenger.removeChatTicket(ticket);
		},
		addPrivateTicketShowInvitationListener:    function(callback) {
			MessengerStore.addPrivateTicketShowInvitationListener(callback);
		},
		removePrivateTicketShowInvitationListener: function(callback) {
			MessengerStore.removePrivateTicketShowInvitationListener(callback);
		},
		handlePrivateTicketShowInvitation:         function(actorName, actorProfilePictureUrl, actorId) {
			MessengerStore.handlePrivateTicketShowInvitation(actorName, actorProfilePictureUrl, actorId);
		},
	},

	Messenger: {

		/**
		 * init
		 */
		init: function(
			language,
			channels,
			channelsFilteredBy,
			showOnline,
			navbarMessages
		) {
			if (!Flux.Messenger.isInitialized()) {
				MessengerChannelsStore.init(channels, navbarMessages, channelsFilteredBy, showOnline);
				MessengerActorStore.init(language);
				initHelper.setReady(stores.MESSENGER);
			}
		},

		isInitialized: function() {
			return initHelper.isReady(stores.MESSENGER);
		},

		/**
		 * add on ready listener
		 */
		onReady: function(listener) {
			initHelper.addReadyListener(stores.MESSENGER, listener);
		},

		// listeners

		addActorChangeListener: function(callback) {
			MessengerActorStore.addActorChangeListener(callback);
		},

		removeActorChangeListener: function(callback) {
			MessengerActorStore.removeActorChangeListener(callback);
		},

		addChannelChangeListener: function(callback) {
			MessengerChannelsStore.addChannelChangeListener(callback);
		},

		removeChannelChangeListener: function(callback) {
			MessengerChannelsStore.removeChannelChangeListener(callback);
		},

		addChannelsChangeListener: function(callback) {
			MessengerChannelsStore.addChannelsChangeListener(callback);
		},

		removeChannelsChangeListener: function(callback) {
			MessengerChannelsStore.removeChannelsChangeListener(callback);
		},

		addChannelSelectedListener: function(callback) {
			MessengerChannelsStore.addChannelSelectedListener(callback);
		},

		removeChannelSelectedListener: function(callback) {
			MessengerChannelsStore.removeChannelSelectedListener(callback);
		},

		addRecommendedActorsChangeListener: function(callback) {
			MessengerChannelsStore.addRecommendedActorsChangeListener(callback);
		},

		removeRecommendedActorsChangeListener: function(callback) {
			MessengerChannelsStore.removeRecommendedActorsChangeListener(callback);
		},

		addRecommendedActorsGridChangeListener: function(callback) {
			MessengerChannelsStore.addRecommendedActorsGridChangeListener(callback);
		},

		removeRecommendedActorsGridChangeListener: function(callback) {
			MessengerChannelsStore.removeRecommendedActorsGridChangeListener(callback);
		},

		addNavbarMessageChangeListener: function(callback) {
			MessengerChannelsStore.addMessagesChangeListener(callback);
		},

		removeNavbarMessageChangeListener: function(callback) {
			MessengerChannelsStore.removeMessagesChangeListener(callback);
		},

		addSearchTextChangeListener: function(callback) {
			MessengerChannelsStore.addSearchTextChangeListener(callback);
		},

		removeSearchTextChangeListener: function(callback) {
			MessengerChannelsStore.removeSearchTextChangeListener(callback);
		},

		addSearchTextClearListener: function(callback) {
			MessengerChannelsStore.addSearchTextClearListener(callback);
		},

		removeSearchTextClearListener: function(callback) {
			MessengerChannelsStore.removeSearchTextClearListener(callback);
		},


		// actions
		setAllMessagesRead: function() {
			ActionCreator.Messenger.setAllMessagesRead();
		},

		setRestorePreviousUrl: function(restorePreviousUrl) {
			ActionCreator.Messenger.setRestorePreviousUrl(restorePreviousUrl);
		},

		loadMoreChannels: function(limit) {
			const channels = MessengerChannelsStore.getChannels();
			const offset   = channels ? channels.length : 0;
			ActionCreator.Notification.loadMoreChannels(limit, offset);
		},

		loadOnlineChannels: function(online) {
			ActionCreator.Notification.loadOnlineChannels(online);
		},

		filterChannels: function(filterType) {
			ActionCreator.Notification.filterChannels(filterType);
		},

		filterOnlineChannels: function(active) {
			ActionCreator.Messenger.filterOnlineChannels(active);
		},

		updateRecommendedActors: function(currentActorId, fsk) {
			ActionCreator.Messenger.updateRecommendedActors(currentActorId, fsk);
		},

		updateRecommendedActorsGrid: function(currentActorId) {
			ActionCreator.Messenger.updateRecommendedActorsGrid(currentActorId);
		},

		/**
		 * Opens the messenger using the given channel ID
		 *
		 * @param {int} channelId
		 * @param {boolean} [storeAutoOpenSetting] Set to true to re-open this channel when the user reloads the website.
		 */
		selectChannelById: function(channelId, storeAutoOpenSetting) {
			ActionCreator.Messenger.selectChannelById(channelId);

			if (storeAutoOpenSetting && channelId && Flux.Guest.isLoggedIn()) {
				BrowserStore.setLocalItem('channelId', channelId);
			}
		},

		/**
		 * Opens the messenger using the given actor ID
		 *
		 * @param {int} actorId
		 * @param {boolean} [storeAutoOpenSetting] Set to true to re-open this channel when the user reloads the website.
		 * @param touchChannel
		 * @param isRestored
		 * @param shouldSelect
		 */
		selectChannelByActorId: function(actorId, storeAutoOpenSetting, touchChannel, isRestored, shouldSelect) {
			ActionCreator.Messenger.selectChannelByActorId(actorId, touchChannel, isRestored, shouldSelect);

			if (storeAutoOpenSetting && actorId && Flux.Guest.isLoggedIn()) {
				BrowserStore.setLocalItem('actorId', actorId);
			}
		},

		/**
		 * Provides a channel for actorId
		 *
		 * @param {int} actorId
		 */
		provideChannelByActorId: function(actorId) {
			ActionCreator.Messenger.selectChannelByActorId(actorId, null, null, false);
		},

		restoreChannel: function(touchChannel) {
			const channelId = BrowserStore.getLocalItem('channelId');
			const actorId   = BrowserStore.getLocalItem('actorId');

			if (channelId) {
				ActionCreator.Messenger.selectChannelById(channelId, touchChannel, true);
			} else if (actorId) {
				ActionCreator.Messenger.selectChannelByActorId(actorId, touchChannel, true);
			}
		},

		clearChannel: function() {
			BrowserStore.setLocalItem('channelId', '');
			BrowserStore.setLocalItem('actorId', '');
		},

		isInSearchMode: function() {
			return MessengerChannelsStore.isInSearchMode();
		},

		isInOnlineMode: function() {
			return MessengerChannelsStore.isInOnlineMode();
		},

		isRestorePreviousUrl: function() {
			return MessengerChannelsStore.isRestorePreviousUrl();
		},

		setAllChannelsSeen: function() {
			ActionCreator.Notification.setAllChannelsSeen();
		},

		unselectChannel: function() {
			ActionCreator.Messenger.unselectChannel();
		},

		sendMessage: function(channelId, senderType, messageType, isVideochat, isFreechat, data, includedGifts = [], chatId = 0) {
			ActionCreator.Notification.sendMessage(channelId, senderType, messageType, isVideochat, isFreechat, data, includedGifts, chatId);
		},

		touchChannel: function(channel) {
			MessengerChannelsStore.touchChannel(channel);
		},

		setUnreadMessagesCount: function(unreadCount) {
			ActionCreator.Messenger.setUnreadMessagesCount(unreadCount);
		},

		setSearchText: function(searchText) {
			MessengerChannelsStore.setSearchText(searchText);
		},

		clearSearchText: function() {
			MessengerChannelsStore.clearSearchText();
		},

		// getter

		getNavbarMessages: function() {
			return MessengerChannelsStore.getNavbarMessages();
		},

		getUnreadMessagesCount: function() {
			return MessengerChannelsStore.getUnreadMessagesCount();
		},

		getActor: function() {
			return MessengerActorStore.getActor();
		},

		updateActor: function(actorId) {
			return MessengerActorStore.updateActor(actorId);
		},

		getChannels: function() {
			return MessengerChannelsStore.getChannels();
		},

		getChannelByActor: function(actorId) {
			return MessengerChannelsStore.getChannelByActor(actorId);
		},

		getLastViewedChannels: function() {
			return MessengerChannelsStore.getLastViewedChannels();
		},

		channelsFilteredBy() {
			return MessengerChannelsStore.channelsFilteredBy();
		},

		getRecommendedActors: function() {
			return MessengerChannelsStore.getRecommendedActors();
		},

		getRecommendedActorsGrid: function() {
			return MessengerChannelsStore.getRecommendedActorsGrid();
		},

		removeRecommendedActor: function(actorId) {
			return MessengerChannelsStore.removeRecommendedActor(actorId);
		},

		getSelectedChannel: function() {
			return MessengerChannelsStore.getSelectedChannel();
		},

		getSelectedChannelId: function() {
			return MessengerChannelsStore.getSelectedChannelId();
		},
	},

	Notification: {

		/**
		 * init
		 */
		init: function(url) {
			if (!initHelper.isReady(stores.NOTIFICATION)) {
				NotificationStore.init(url);
				initHelper.setReady(stores.NOTIFICATION);
			}
		},

		/**
		 * add on ready listener
		 */
		onReady: function(listener) {
			initHelper.addReadyListener(stores.NOTIFICATION, listener);
		},

		// listeners

		addNotificationChangeListener: function(callback) {
			NotificationStore.addNotificationChangeListener(callback);
		},

		removeNotificationChangeListener: function(callback) {
			NotificationStore.removeNotificationChangeListener(callback);
		},

		addClashOfCamsChallengeListener: function(callback) {
			NotificationStore.addClashOfCamsChallengeListener(callback);
		},

		removeClashOfCamsChallengeListener: function(callback) {
			NotificationStore.removeClashOfCamsChallengeListener(callback);
		},

		// actions

		setChannelSeen: function(channelId) {
			ActionCreator.Notification.setChannelSeen(channelId);
		},

		canOneClick: function() {
			ActionCreator.Notification.canOneClick();
		},

		chargeOneClick: function(amount) {
			ActionCreator.Notification.chargeOneClick(amount, MessengerChannelsStore.getSelectedChannelId());
		},

		archiveChannel: function(channelId) {
			ActionCreator.Notification.archiveChannel(channelId, true);
		},

		unarchiveChannel: function(channelId) {
			ActionCreator.Notification.archiveChannel(channelId, false);
		},

		/*
		 * Navbar Notifications
		 */

		addNavbarNotificationsChangeListener: function(callback) {
			NotificationStore.addNavbarNotificationsChangeListener(callback);
		},

		removeNavbarNotificationsChangeListener: function(callback) {
			NotificationStore.removeNavbarNotificationsChangeListener(callback);
		},

		getNavbarNotifications: function() {
			return NotificationStore.getNavbarNotifications();
		},

		markNavbarNotificationAsRead: function(notificationId) {
			const found = NotificationStore.markNavbarNotificationAsRead(notificationId);
			if (found) { // only send event if notification was unread
				ActionCreator.Notification.markNavbarNotificationAsRead(notificationId);
			}
		},

		markNavbarNotificationAsSeen: function(notificationId) {
			const found = NotificationStore.markNavbarNotificationAsSeen(notificationId);
			if (found) { // only send event if notification was unseen
				ActionCreator.Notification.markNavbarNotificationAsSeen(notificationId);
			}
		},

		markAllNavbarNotificationsAsSeen: function() {
			const notificationsIds = NotificationStore.markAllNavbarNotificationsAsSeen();
			if (notificationsIds.length) {
				for (let i = 0; i < notificationsIds.length; i++) {
					const notificationId = notificationsIds[i];
					ActionCreator.Notification.markNavbarNotificationAsSeen(notificationId);
				}
			}
		},

		markAllNavbarNotificationsAsRead: function() {
			const notificationsIds = NotificationStore.markAllNavbarNotificationsAsRead();
			if (notificationsIds.length) {
				for (let i = 0; i < notificationsIds.length; i++) {
					const notificationId = notificationsIds[i];
					ActionCreator.Notification.markNavbarNotificationAsRead(notificationId);
				}
			}
		},

		addFloatingNotificationsChangeListener: function(callback) {
			NotificationStore.addFloatingNotificationsChangeListener(callback);
		},

		removeFloatingNotificationsChangeListener: function(callback) {
			NotificationStore.removeFloatingNotificationsChangeListener(callback);
		},

		getFloatingNotifications: function() {
			return NotificationStore.getFloatingNotifications();
		},

		markFloatingNotificationAsRead: function(notificationId) {
			const found = NotificationStore.markFloatingNotificationAsRead(notificationId);
			if (found) { // only send event if notification was unread
				ActionCreator.Notification.markFloatingNotificationAsRead(notificationId);
			}
		},

		markFloatingNotificationAsSeen: function(notificationId) {
			const found = NotificationStore.markFloatingNotificationAsSeen(notificationId);
			if (found) { // only send event if notification was unseen
				ActionCreator.Notification.markFloatingNotificationAsSeen(notificationId);
			}
		},

		/*
		 * Guest state changes
		 */

		refreshEmailProperties: function() {
			ActionCreator.Notification.refreshEmailProperties();
		},

		passwordChanged: function() {
			ActionCreator.Notification.passwordChanged();
		},

		sendSignupEmail: function(email) {
			ActionCreator.Notification.sendSignupEmail(email);
		},
		//getter

		getNotifications: function(lastId, filters) {
			return NotificationStore.getNotifications(lastId, filters);
		},

		removeStatusBar: function() {
			ActionCreator.Notification.removeStatusBar();
		},
	},

	ProSearch: {

		/**
		 * init
		 */
		init: function(
			activeCategory,
			activeFilterList,
			availableFilterList,
			actorList,
			currentPage,
			maxPage,
			totalHits,
			proSearchType
		) {
			ProSearchMetaStore.init(
				activeCategory,
				activeFilterList,
				availableFilterList,
				actorList,
				currentPage,
				maxPage,
				totalHits,
				proSearchType,
			);
			initHelper.setReady(stores.PROSEARCH);
		},

		initMobile: function(proSearchType, availableCollections, availableFilters) {
			ProSearchMetaStore.initMobile(proSearchType, availableCollections, availableFilters);
		},

		isInitialized: function(searchType) {
			return ProSearchMetaStore.getIsInitialized(searchType);
		},

		setCategory: function(category, page, searchType) {
			ProSearchActionCreator.setCategory(category, page, searchType);
		},

		isReady: function() {
			return initHelper.isReady(stores.PROSEARCH);
		},

		setNotReady: function() {
			initHelper.resetReadyState(stores.PROSEARCH);
		},

		onReady: function(listener) {
			initHelper.addReadyListener(stores.PROSEARCH, listener);
		},

		getActiveCategory: function(searchType) {
			return ProSearchMetaStore.getActiveCategory(searchType);
		},

		getAvailableFilterList: function(searchType) {
			return ProSearchMetaStore.getAvailableFilterList(searchType);
		},

		getActiveFilterList: function(searchType) {
			return ProSearchMetaStore.getActiveFilterList(searchType);
		},

		getActiveSort: function(searchType) {
			return ProSearchMetaStore.getActiveSort(searchType);
		},

		getActorList: function(searchType) {
			return ProSearchMetaStore.getActorList(searchType);
		},

		getMarkersList: function(searchType) {
			return ProSearchMetaStore.getMarkersList(searchType);
		},

		getCurrentPage: function(searchType) {
			return ProSearchMetaStore.getCurrentPage(searchType);
		},

		getMaxPage: function(searchType) {
			return ProSearchMetaStore.getMaxPage(searchType);
		},

		getTotalHits: function(searchType) {
			return ProSearchMetaStore.getTotalHits(searchType);
		},

		getAvailableCollectionsList: function(searchType) {
			return ProSearchMetaStore.getAvailableCollectionsList(searchType);
		},

		isCollectionListReady: function(searchType) {
			return ProSearchMetaStore.isCollectionListReady(searchType);
		},

		getActorMapState: function(searchType) {
			return ProSearchMetaStore.getActorMapState(searchType);
		},

		// listeners

		addChangeListener: function(callback) {
			ProSearchMetaStore.addChangeListener(callback);
		},

		removeChangeListener: function(callback) {
			ProSearchMetaStore.removeChangeListener(callback);
		},

		addFitZipBoundsListener: function(callback) {
			ProSearchMetaStore.addFitZipBoundsListener(callback);
		},

		removeFitZipBoundsListener: function(callback) {
			ProSearchMetaStore.removeFitZipBoundsListener(callback);
		},

		// actions

		loadPage: function(page, searchType, append = false) {
			ProSearchActionCreator.loadPage(page, searchType, append);
		},

		changeSearchCondition: function(field, value, valueTranslation, checked, searchType, doSearch = true) {
			ProSearchActionCreator.changeSearchCondition(field, value, valueTranslation, checked, searchType, doSearch);
		},

		changeSortType: function(sortType, searchType, doSearch = true) {
			ProSearchActionCreator.changeSortType(sortType, searchType, doSearch);
		},

		deleteFilter: function(searchType) {
			ProSearchActionCreator.deleteFilter(searchType);
		},

		changeMoodValue: function(moodValue, searchType) {
			ProSearchActionCreator.changeMoodValue(moodValue, searchType);
		},

		/**
		 * @param {Number|String} category
		 * @param {String} searchType
		 * @param {String} sortType
		 */
		doInit: function(
			category,
			searchType = Flux.Constants.ActorSearch.SearchTypes.SEARCH_TYPE_DEFAULT,
			sortType   = Flux.Constants.ActorSearch.SortTypes.SEARCH_SORT_TYPE_RANK
		) {
			ProSearchActionCreator.init(category, searchType, () => initHelper.setReady(stores.PROSEARCH), sortType);
		},

		setActorOnlineState: function(actorId, state) {
			ProSearchActionCreator.setActorOnlineState(actorId, state);
		},

		setActorMapState: function(state, searchType) {
			ProSearchActionCreator.setActorMapState(state, searchType);
		},

		fitZipBounds: function() {
			ProSearchMetaStore.emitFitZipBounds();
		},

		setSearchLocationByZip(zipCode) {
			ProSearchActionCreator.setSearchLocationByZip(zipCode);
		},

		setSearchLocationToPrevious() {
			ProSearchMetaStore.setSearchLocationToPrevious();
		},

		addSearchLocationChangeListener: function(callback) {
			ProSearchMetaStore.addSearchLocationChangeListener(callback);
		},

		removeSearchLocationChangeListener: function(callback) {
			ProSearchMetaStore.removeSearchLocationChangeListener(callback);
		},

		getLastSearchedZip: function() {
			return ProSearchMetaStore.getLastSearchedZip();
		},

		setLastSearchedZip: function(zip) {
			ProSearchActionCreator.setLastSearchedZip(zip);
		},

		addZipFieldChangeListener: function(callback) {
			ProSearchMetaStore.addZipFieldChangeListener(callback);
		},

		removeZipFieldChangeListener: function(callback) {
			ProSearchMetaStore.removeZipFieldChangeListener(callback);
		},

		setGuestGeoLocation: function(geoLocation) {
			ProSearchActionCreator.setGuestGeoLocation(geoLocation);
		},

	},

	GlobalSearch: {
		init:                                  function(suggestions, lastSearchRequests, searchUrl) {
			GlobalSearchStore.init(suggestions, lastSearchRequests, searchUrl);
		},
		doSearch:                              function(searchPattern, isExtended = false) {
			GlobalSearchStore.doSearch(searchPattern, isExtended);
		},
		doSuggestions:                         function(searchPattern, isExtended = false) {
			GlobalSearchStore.doSuggestions(searchPattern, isExtended);
		},
		showOverlay:                           function() {
			GlobalSearchStore.showOverlay();
		},
		hideOverlay:                           function() {
			GlobalSearchStore.hideOverlay();
		},
		getSearchResult:                       function() {
			return GlobalSearchStore.getSearchResult();
		},
		getSuggestions:                        function() {
			return GlobalSearchStore.getSuggestions();
		},
		getLastSearchRequests:                 function() {
			return GlobalSearchStore.getLastSearchRequests();
		},
		getSearchUrl:                          function() {
			return GlobalSearchStore.getSearchUrl();
		},
		storeSearchRequest:                    function(searchPattern) {
			GlobalSearchStore.storeSearchRequest(searchPattern);
		},
		addOverlayVisibilityChangeListener:    function(callback) {
			GlobalSearchStore.addOverlayVisibilityChangeListener(callback);
		},
		removeOverlayVisibilityChangeListener: function(callback) {
			GlobalSearchStore.removeOverlayVisibilityChangeListener(callback);
		},
		addSearchResultListener:               function(callback) {
			GlobalSearchStore.addSearchResultListener(callback);
		},
		removeSearchResultListener:            function(callback) {
			GlobalSearchStore.removeSearchResultListener(callback);
		},
		processSuggestions:                    function(callback) {
			GlobalSearchStore.processSuggestions(callback);
		},
	},

	LiveReactions: {
		addLiveReaction: function(callback, referenceType, referenceId, imageId, message, data) {
			LiveReactionsStore.addReaction(callback, referenceType, referenceId, imageId, message, data);
		},

		handleLiveReaction: function(liveReaction) {
			LiveReactionsStore.handleLiveReaction(liveReaction);
		},

		addLiveReactionListener: function(callback) {
			LiveReactionsStore.addLiveReactionListener(callback);
		},

		removeLiveReactionListener: function(callback) {
			LiveReactionsStore.removeLiveReactionListener(callback);
		},
	},

	Search: {
		doSearch:                                 function(searchPattern, category, filter, offset = 0, first = 0, saveRequest = false) {
			SearchStore.doSearch(searchPattern, category, filter, offset, first, saveRequest);
		},
		getLastRequest:                           function() {
			return SearchStore.getLastRequest();
		},
		getCurrentSearchPattern:                  function() {
			return SearchStore.getCurrentSearchPattern();
		},
		setCurrentSearchPattern:                  function(searchPattern) {
			SearchStore.setCurrentSearchPattern(searchPattern);
		},
		addCurrentSearchpatternChangeListener:    function(callback) {
			SearchStore.addCurrentSearchpatternChangeListener(callback);
		},
		removeCurrentSearchpatternChangeListener: function(callback) {
			SearchStore.removeCurrentSearchpatternChangeListener(callback);
		},
		addSearchResultListener:                  function(callback) {
			SearchStore.addSearchResultListener(callback);
		},
		removeSearchResultListener:               function(callback) {
			SearchStore.removeSearchResultListener(callback);
		},
		clearCache:                               function(categories) {
			SearchStore.clearCache(categories);
		},
        clearVideoCache:                          function() {
            SearchStore.clearVideoCache();
        }

	},

	SearchResult: {
		init: function(language) {
			SearchResultStore.init(language);
		},

		addFullResultChangeListener: function(callback) {
			SearchResultStore.addFullResultChangeListener(callback);
		},

		addSeachByTypeResultChangeListener: function(callback) {
			SearchResultStore.addSeachByTypeResultChangeListener(callback);
		},

		removeFullResultChangeListener: function(callback) {
			SearchResultStore.removeFullResultChangeListener(callback);
		},

		addQuickResultChangeListener: function(callback) {
			SearchResultStore.addQuickResultChangeListener(callback);
		},

		removeQuickResultChangeListener: function(callback) {
			SearchResultStore.removeQuickResultChangeListener(callback);
		},

		clearResultFull: function(searchPattern) {
			ActionCreator.SearchResult.clearResultFull(searchPattern);
		},

		getSearchPatternFull: function() {
			return SearchResultStore.getSearchPatternFull();
		},

		getSearchPatternQuick: function() {
			return SearchResultStore.getSearchPatternQuick();
		},

		getSearchResultsFull: function(searchPattern, limit) {
			return SearchResultStore.getSearchResultsFull(searchPattern, limit);
		},

		getSearchResultsByType: function(searchPattern, type, sort, order) {
			return SearchResultStore.getSearchResultsByType(searchPattern, type, sort, order);
		},

		getSearchResultsCurrentCount: function(searchPattern, searchType, sort, order) {
			return SearchResultStore.getSearchResultsCurrentCount(searchPattern, searchType, sort, order);
		},

		getSearchResultsSortingOptions: function(searchPattern, searchType, sort, order) {
			return SearchResultStore.getSearchResultsSortingOptions(searchPattern, searchType, sort, order);
		},

		getSearchResultsTotalCount: function(searchPattern, searchType, sort, order) {
			return SearchResultStore.getSearchResultsTotalCount(searchPattern, searchType, sort, order);
		},

		getSearchResultsQuick: function(limit) {
			return SearchResultStore.getSearchResultsQuick(limit);
		},

		getSearchResultsMoreLink: function() {
			return SearchResultStore.getSearchResultsMoreLink();
		},

		getMediaSearchResultsCount: function() {
			return SearchResultStore.getMediaSearchResultsCount();
		},

		getActorsSearchResultsCount: function() {
			return SearchResultStore.getActorsSearchResultsCount();
		},

		getVideosSearchResultsCount: function() {
			return SearchResultStore.getVideosSearchResultsCount();
		},

		getMediathekSearchResultsCount: function() {
			return SearchResultStore.getMediathekSearchResultsCount();
		},

		getArticlesSearchResultsCount: function() {
			return SearchResultStore.getArticlesSearchResultsCount();
		},

		isSearchByTypeRedirectCase: function() {
			return SearchResultStore.isSearchByTypeRedirectCase();
		},

		setSearchResultsType: function(searchType) {
			SearchResultStore.setSearchResultsType(searchType);
		},

		getSearchResultsType: function() {
			return SearchResultStore.getSearchResultsType();
		},
		getSearchType:        function() {
			return SearchResultStore.getSearchType();
		},

		setActorOnlineState: function(actorId, state) {
			ActionCreator.SearchResult.setActorOnlineState(actorId, state);
		},

		setSearchByTypeSearchType(searchType) {
			SearchResultStore.setSearchPattern(searchType);
		},

		setSearchPatternFull: function(searchPattern) {
			ActionCreator.SearchResult.setSearchPatternFull(searchPattern);
		},

		setSearchPatternByType: function(searchPattern, searchType, sort, order) {
			ActionCreator.SearchResult.setSearchPatternByType(searchPattern, searchType, sort, order);
		},

		setSearchPatternQuick: function(searchPattern) {
			ActionCreator.SearchResult.setSearchPatternQuick(searchPattern);
		},
	},


	Special: {
		addGamePointsChangeListener: function(callback) {
			SpecialStore.addGamePointsChangeListener(callback);
		},

		removeGamePointsChangeListener: function(callback) {
			SpecialStore.removeGamePointsChangeListener(callback);
		},

		addRewardChangeListener: function(callback) {
			SpecialStore.addRewardChangeListener(callback);
		},

		removeRewardChangeListener: function(callback) {
			SpecialStore.removeRewardChangeListener(callback);
		},

		addRewardHistoryChangeListener: function(callback) {
			SpecialStore.addRewardHistoryChangeListener(callback);
		},

		removeRewardHistoryChangeListener: function(callback) {
			SpecialStore.removeRewardHistoryChangeListener(callback);
		},

		addTriesChangeListener: function(callback) {
			SpecialStore.addTriesChangeListener(callback);
		},

		removeTriesChangeListener: function(callback) {
			SpecialStore.removeTriesChangeListener(callback);
		},

		addEasterChangeListener: function(callback) {
			SpecialStore.addEasterChangeListener(callback);
		},

		removeEasterChangeListener: function(callback) {
			SpecialStore.removeEasterChangeListener(callback);
		},

		getGamePointsByType: function(type) {
			return SpecialStore.getGamePointsByType(type);
		},

		getReward: function() {
			return SpecialStore.getReward();
		},

		getRewardHistory: function() {
			return SpecialStore.getRewardHistory();
		},

		getSpecialEventData: function(eventType) {
			return SpecialStore.getSpecialEventData(eventType);
		},

		getTriesAvailable: function() {
			return SpecialStore.getTriesAvailable();
		},

		getTriesTotal: function() {
			return SpecialStore.getTriesTotal();
		},

		setActorOnlineState: function(actorId, state) {
			ActionCreator.Special.setActorOnlineState(actorId, state);
		},

		setGamePoints: function(gamePoints) {
			ActionCreator.Special.setGamePoints(gamePoints);
		},

		setLastOnTopInactiveByCode: function(code) {
			ActionCreator.Special.setLastOnTopInactiveByCode(code);
		},

		setReward: function(reward) {
			ActionCreator.Special.setReward(reward);
		},

		setRewardHistory: function(rewardHistory) {
			ActionCreator.Special.setRewardHistory(rewardHistory);
		},

		setTries: function(available, total) {
			ActionCreator.Special.setTries(available, total);
		},

		getSpecials: function(offset, limit) {
			return SpecialStore.getSpecials(offset, limit);
		},

		getSpecialsCount: function() {
			return SpecialStore.getSpecialsCount();
		},

		loadSpecialEventData: function(eventType, qmDate) {
			ActionCreator.Special.loadSpecialEventData(eventType, qmDate);
		},

		loadSpecials: function() {
			ActionCreator.Special.loadSpecials();
		},

		addSpecialEventDataChangeListener: function(callback) {
			SpecialStore.addSpecialEventDataChangeListener(callback);
		},

		removeSpecialEventDataChangeListener: function(callback) {
			SpecialStore.removeSpecialEventDataChangeListener(callback);
		},

		addSpecialsChangeListener: function(callback) {
			SpecialStore.addSpecialsChangeListener(callback);
		},

		removeSpecialsChangeListener: function(callback) {
			SpecialStore.addSpecialsChangeListener(callback);
		},

		collectEgg: function(verificationToken, qmDate) {
			ActionCreator.Special.collectEgg(verificationToken, qmDate);
		},

		loadVenusAttacksReward: function(qmDate) {
			ActionCreator.Special.loadVenusAttacksReward(qmDate);
		},

		loadWiesnShootingReward: function(qmDate) {
			ActionCreator.Special.loadWiesnShootingReward(qmDate);
		},

		loadWetAndWildReward: function(eventId, qmDate) {
			ActionCreator.Special.loadWetAndWildReward(eventId, qmDate);
		},

		loadWiesnBudeReward: function(qmDate) {
			ActionCreator.Special.loadWiesnBudeReward(qmDate);
		},

		loadRewardBoxReward: function(eventId, qmDate) {
			ActionCreator.Special.loadRewardBoxReward(eventId, qmDate);
		},

		loadWiesnGaudiReward: function(qmDate) {
			ActionCreator.Special.loadWiesnGaudiReward(qmDate);
		},

		loadHalloweenReward: function(qmDate, eventId) {
			ActionCreator.Special.loadHalloweenReward(qmDate, eventId);
		},

		loadSpitOrSwallowConfig() {
			ActionCreator.Special.loadSpitOrSwallowConfig();
		},

		getSpitOrSwallowConfig() {
			return SpecialStore.getSpitOrSwallowConfig();
		},

		addSpitOrSwallowConfigChangeListener(callback) {
			SpecialStore.addSpitOrSwallowConfigChangeListener(callback);
		},

		removeSpitOrswallowConfigChangeListener(callback) {
			SpecialStore.removeSpitOrSwallowConfigChangeListener(callback);
		},

		showLoader(type) {
			ActionCreator.Special.showLoader(type);
		},

		hideLoader() {
			ActionCreator.Special.hideLoader();
		},

		addShowLoaderChangeListener(callback) {
			SpecialStore.addShowLoaderChangeListener(callback);
		},

		removeShowLoaderChangeListener(callback) {
			SpecialStore.removeShowLoaderChangeListener(callback);
		}
	},

	Survey: {
		addSurveyChangeListener: function(callback) {
			SurveyStore.addSurveyChangeListener(callback);
		},

		removeSurveyChangeListener: function(callback) {
			SurveyStore.removeSurveyChangeListener(callback);
		},

		addSurveyActiveChangeListener: function(callback) {
			SurveyStore.addSurveyActiveChangeListener(callback);
		},

		removeSurveyActiveChangeListener: function(callback) {
			SurveyStore.removeSurveyActiveChangeListener(callback);
		},

		addSurveyBlockedChangeListener: function(callback) {
			SurveyStore.addSurveyBlockedChangeListener(callback);
		},

		removeSurveyBlockedChangeListener: function(callback) {
			SurveyStore.removeSurveyBlockedChangeListener(callback);
		},

		blockSurvey: function(type) {
			ActionCreator.Survey.blockSurvey(type);
		},

		closeSurvey: function(type) {
			ActionCreator.Survey.closeSurvey(type);
		},

		getActiveSurveyType: function() {
			return SurveyStore.getActiveSurveyType();
		},

		getSurveyData: function(type) {
			return SurveyStore.getSurveyData(type);
		},

		isSurveyBlocked: function(type) {
			return SurveyStore.isSurveyBlocked(type);
		},

		loadFeedbackData: function() {
			ActionCreator.Survey.loadFeedbackData();
		},

		openSurvey: function(type) {
			ActionCreator.Survey.openSurvey(type);
		},

		storeChatRating: function(chatId, actorId, rating, message, isMobile, isAnonymousVoicecall) {
			ActionCreator.Survey.storeChatRating(chatId, actorId, rating, message, isMobile, isAnonymousVoicecall);
		},

		storeFeedbackAnswer: function(isMobile, feedback, email) {
			ActionCreator.Survey.storeFeedbackAnswer(isMobile, feedback, email);
		},
	},

	Teaser: {
		loadTeasers: function(routeName, routeArgs) {
			ActionCreator.Teaser.loadTeasers(routeName, routeArgs);
		},

		addTeasersChangeListener: function(callback) {
			TeaserStore.addTeasersChangeListener(callback);
		},

		removeTeasersChangeListener: function(callback) {
			TeaserStore.removeTeasersChangeListener(callback);
		},

		getTeasers: function() {
			return TeaserStore.getTeasers(Flux.VXMobile.getRouteName(), Flux.VXMobile.getRouteArgs());
		},

		hasTeasers:                            function() {
			return TeaserStore.hasTeasers(Flux.VXMobile.getRouteName(), Flux.VXMobile.getRouteArgs());
		},
		getPrivateShopDiscountMobileBannerUrl: function() {
			return TeaserStore.getPrivateShopDiscountMobileBannerUrl();
		},
	},

	TileGrid: {
		getGridData: function(type, payload) {
			return TileGridStore.getGridData(type, payload);
		},

		hasGridData: function(type, payload) {
			return TileGridStore.hasGridData(type, payload);
		},

		loadGrid: function(type, payload, useInfiniteScrolling) {
			ActionCreator.TileGrid.loadGrid(type, payload, useInfiniteScrolling);
		},

		/**
		 * @param {Function} callback
		 */
		addOnGridCropListener: function(callback) {
			TileGridStore.addOnGridCropListener(callback);
		},

		/**
		 * @param {Function} callback
		 */
		removeOnGridCropListener: function(callback) {
			TileGridStore.removeOnGridCropListener(callback);
		},

		addGridDataChangeListener: function(callback) {
			TileGridStore.addGridDataChangeListener(callback);
		},

		addGlobalPinChangeListener: function(callback) {
			TileGridStore.addGlobalPinChangeListener(callback);
		},

		removeGlobalPinChangeListener: function(callback) {
			TileGridStore.removeGlobalPinChangeListener(callback);
		},

		removeGridDataChangeListener: function(callback) {
			TileGridStore.removeGridDataChangeListener(callback);
		},

		removePinnedTileElement: function(type, payload, tileIndex) {
			return TileGridStore.removePinnedTileElement(type, payload, tileIndex);
		},

		addVideoTileDataChangeListener: function(callback) {
			TileGridStore.addVideoTileDataChangeListener(callback);
		},

		removeVideoTileDataChangeListener: function(callback) {
			TileGridStore.removeVideoTileDataChangeListener(callback);
		},

		getPictureData: function(albumId) {
			return TileGridStore.getPictureData(albumId);
		},

		loadVideoAlbumPictures: function(albumId) {
			ActionCreator.TileGrid.loadVideoAlbumPictures(albumId);
		},

		getScrollPositionById: function(tileGridId) {
			return TileGridStore.getScrollPositionById(tileGridId);
		},

		setScrollPosition: function(tileGridId, offset) {
			ActionCreator.TileGrid.setScrollPosition(tileGridId, offset);
		},

		storePinBoardPayload: function(pinBoardType, payload) {
			TileGridStore.storePinBoardPayload(pinBoardType, payload);
		},

		getPinBoardPayload: function(pinBoardType) {
			return TileGridStore.getPinBoardPayload(pinBoardType);
		},

		getBackLinkById: function(tileGridId) {
			return TileGridStore.getBackLinkById(tileGridId);
		},

		setBackLink: function(tileGridId) {
			ActionCreator.TileGrid.setBackLink(tileGridId);
		},

		resetScrollPosition: function(tileGridId) {
			ActionCreator.TileGrid.resetScrollPosition(tileGridId);
		},

		resetBackLink: function(tileGridId) {
			ActionCreator.TileGrid.resetBackLink(tileGridId);
		},

		setRestoreScrollPosition: function(restorePosition) {
			ActionCreator.TileGrid.setRestoreScrollPosition(restorePosition);
		},

		getRestoreScrollPosition: function() {
			return TileGridStore.getRestoreScrollPosition();
		},

		setTileGridKey: function(tileGridId, tileGridKey) {
			ActionCreator.TileGrid.setTileGridKey(tileGridId, tileGridKey);
		},

		getTileGridKeyById: function(tileGridId) {
			return TileGridStore.getTileGridKeyById(tileGridId);
		},

		setTileGridMode: function(tileGridId, gridMode) {
			ActionCreator.TileGrid.setTileGridMode(tileGridId, gridMode);
		},

		getTileGridModeById: function(tileGridId) {
			return TileGridStore.getTileGridModeById(tileGridId);
		},

		setFilterList: function(filterList) {
			TileGridStore.setTileGridFilterList(filterList);
		},

		getFilterList: function() {
			return TileGridStore.getFilterList();
		},

		addFilterListChangeListener: function(callback) {
			TileGridStore.addFilterListChangeListener(callback);
		},

		removeFilterListChangeListener: function(callback) {
			TileGridStore.removeFilterListChangeListener(callback);
		},

		/**
		 * @param {String} tileGridId
		 * @param {Boolean} crop
		 */
		toggleGridCrop: function(tileGridId, crop) {
			ActionCreator.TileGrid.toggleGridCrop(tileGridId, crop);
		},
	},

	TVMedia: {

		/**
		 * init
		 */
		init: function() {
			TVMediaStore.init();
		},

		loadFormatData(formatKey) {
			ActionCreator.TV.loadFormatData(formatKey);
		},

		loadPostData(slug) {
			ActionCreator.TV.loadPostData(slug);
		},

		loadVideoData(videoId) {
			ActionCreator.TV.loadVideoData(videoId);
		},

		loadSuggestVideoData(videoId) {
			ActionCreator.TV.loadSuggestVideoData(videoId);
		},


		getFormatData(key) {
			return TVMediaStore.getFormatData(key);
		},

		getPostData(slug) {
			return TVMediaStore.getPostData(slug);
		},

		getVideoData(videoId) {
			return TVMediaStore.getVideoData(videoId);
		},

		getSuggestVideoData(videoId) {
			return TVMediaStore.getSuggestVideoData(videoId);
		},


		addFormatChangeListener: function(callback) {
			TVMediaStore.addFormatChangeListener(callback);
		},

		removeFormatChangeListener: function(callback) {
			TVMediaStore.removeFormatChangeListener(callback);
		},

		addPostChangeListener: function(callback) {
			TVMediaStore.addPostChangeListener(callback);
		},

		removePostChangeListener: function(callback) {
			TVMediaStore.removePostChangeListener(callback);
		},

		addVideoChangeListener: function(callback) {
			TVMediaStore.addVideoChangeListener(callback);
		},

		addSuggestVideoChangeListener: function(callback) {
			TVMediaStore.addSuggestVideoChangeListener(callback);
		},


		removeVideoChangeListener: function(callback) {
			TVMediaStore.removeVideoChangeListener(callback);
		},

		removeSuggestVideoChangeListener: function(callback) {
			TVMediaStore.removeSuggestVideoChangeListener(callback);
		},


		addMediathekVideoRecommendationsChangeListener: function(callback) {
			TVMediaStore.addMediathekVideoRecommendationsChangeListener(callback);
		},

		removeMediathekVideoRecommendationsChangeListener: function(callback) {
			TVMediaStore.removeMediathekVideoRecommendationsChangeListener(callback);
		},

		addMediathekVideoRecommendationsLoadListener: function(callback) {
			TVMediaStore.addMediathekVideoRecommendationsLoadListener(callback);
		},

		removeMediathekVideoRecommendationsLoadListener: function(callback) {
			TVMediaStore.removeMediathekVideoRecommendationsLoadListener(callback);
		},

		getMediathekVideoRecommendations: function(hashId) {
			return TVMediaStore.getMediathekVideoRecommendations(hashId);
		},

		isMediathekVideoRecommendationsLoading: function(hashId) {
			return TVMediaStore.isMediathekVideoRecommendationsLoading(hashId);
		},

		loadMediathekVideoRecommendations: function(hashId, count) {
			ActionCreator.TV.loadMediathekVideoRecommendations(hashId, count);
		},


		loadMediathekVideos: function(filter, count, offset) {
			ActionCreator.TV.loadMediathekVideos(filter, count, offset);
		},

		addMediathekVideosChangeListener: function(callback) {
			TVMediaStore.addMediathekVideosChangeListener(callback);
		},

		removeMediathekVideosChangeListener: function(callback) {
			TVMediaStore.removeMediathekVideosChangeListener(callback);
		},

		addMediathekVideosLoadListener: function(callback) {
			TVMediaStore.addMediathekVideosLoadListener(callback);
		},

		removeMediathekVideosLoadListener: function(callback) {
			TVMediaStore.removeMediathekVideosLoadListener(callback);
		},

		getMediathekVideos: function(filter) {
			return TVMediaStore.getMediathekVideos(filter);
		},

		getMediathekVideosTotalCount: function(filter) {
			return TVMediaStore.getMediathekVideosTotalCount(filter);
		},

		isMediathekVideosLoading: function(filter) {
			return TVMediaStore.isMediathekVideosLoading(filter);
		},


	},

	TVLivestream: {

		/**
		 * init
		 */
		init: function(premium) {
			TVLivestreamStore.init(premium);
		},

		addActorChangeListener: function(callback) {
			TVLivestreamStore.addActorChangeListener(callback);
		},

		removeActorChangeListener: function(callback) {
			TVLivestreamStore.removeActorChangeListener(callback);
		},

		addStreamChangeListener: function(callback) {
			TVLivestreamStore.addStreamChangeListener(callback);
		},

		removeStreamChangeListener: function(callback) {
			TVLivestreamStore.removeStreamChangeListener(callback);
		},

		getCurrentActors: function() {
			return TVLivestreamStore.getCurrentActors();
		},

		getStreamData: function(isPremium) {
			return TVLivestreamStore.getStreamData(isPremium);
		},

		hasStreamData: function(isPremium) {
			return TVLivestreamStore.hasStreamData(isPremium);
		},

		checkPlaylistActors() {
			ActionCreator.TV.checkPlaylistActors();
		},

		loadStreamInfo(premium) {
			ActionCreator.TV.loadStreamInfo(premium);
		},

		loadProgramInfo(premium, limit = 10) {
			ActionCreator.TV.loadProgramInfo(premium, limit);
		},
	},

	VXMobile: {

		init: function(data) {
			VXMobileStore.init(data);
		},

		getLanguage: function() {
			return VXMobileStore.getLanguage();
		},

		getPlatform: function() {
			return VXMobileStore.getPlatform();
		},

		getDesktopLink: function() {
			return VXMobileStore.getDesktopLink();
		},

		getRefererUrl: function() {
			return VXMobileStore.getRefererUrl();
		},

		getScrollToOnMount: function(route) {
			return VXMobileStore.getScrollToOnMount(route);
		},

		isDevServer: function() {
			return VXMobileStore.isDevServer();
		},

		isEntryPage: function() {
			return VXMobileStore.isEntryPage();
		},

		getVxBaseUrl: function() {
			return VXMobileStore.getVxBaseUrl();
		},

		isBeforeInstallPromptSet: function() {
			return VXMobileStore.isBeforeInstallPromptSet();
		},

		isNavbarHidden: function() {
			return VXMobileStore.isNavbarHidden();
		},

		isIOS: function() {
			return VXMobileStore.isIOS();
		},

		shouldRestoreNavbarState: function() {
			return VXMobileStore.shouldRestoreNavbarState();
		},

		addBeforeInstallPromptChangeListener: function(callback) {
			VXMobileStore.addBeforeInstallPromptChangeListener(callback);
		},

		removeBeforeInstallPromptChangeListener: function(callback) {
			VXMobileStore.removeBeforeInstallPromptChangeListener(callback);
		},

		addDisableBodyScrollChangeListener: function(callback) {
			VXMobileStore.addDisableBodyScrollChangeListener(callback);
		},

		removeDisableBodyScrollChangeListener: function(callback) {
			VXMobileStore.removeDisableBodyScrollChangeListener(callback);
		},

		addHideNavbarChangeListener: function(callback) {
			VXMobileStore.addHideNavbarChangeListener(callback);
		},

		removeHideNavbarChangeListener: function(callback) {
			VXMobileStore.removeHideNavbarChangeListener(callback);
		},

		addNavbarFixedListener: function(callback) {
			VXMobileStore.addNavbarFixedListener(callback);
		},

		removeNavbarFixedListener: function(callback) {
			VXMobileStore.removeNavbarFixedListener(callback);
		},

		addScrollPageToListener: function(callback) {
			VXMobileStore.addScrollPageToListener(callback);
		},

		removeScrollPageToListener: function(callback) {
			VXMobileStore.removeScrollPageToListener(callback);
		},

		clickBeforeInstallPrompt: function(install) {
			VXMobileActionCreator.clickBeforeInstallPrompt(install);
		},

		hideNavbar: function(triggerName) {
			VXMobileActionCreator.hideNavbar(triggerName);
		},

		setContentScroll: function(scrollPosition) {
			VXMobileActionCreator.setContentScroll(scrollPosition);
		},

		addContentScrollChangeListener: function(callback) {
			VXMobileStore.addContentScrollChangeListener(callback);
		},

		removeContentScrollChangeListener: function(callback) {
			VXMobileStore.removeContentScrollChangeListener(callback);
		},

		ignoreNextScroll: function(containerId) {
			VXMobileActionCreator.ignoreNextScroll(containerId);
		},

		restoreNavbarState: function(path) {
			VXMobileActionCreator.restoreNavbarState(path);
		},

		scrollPageTo: function(position) {
			VXMobileActionCreator.scrollPageTo(position);
		},

		setRestoreNavbarState: function(restore) {
			VXMobileActionCreator.setRestoreNavbarState(restore);
		},

		setScrollToOnMount: function(route, position) {
			VXMobileActionCreator.setScrollToOnMount(route, position);
		},

		showNavbar: function(triggerName, callback) {
			VXMobileActionCreator.showNavbar(triggerName, callback);
		},

		storeNavbarState: function(path) {
			VXMobileActionCreator.storeNavbarState(path);
		},

		isBodyScrollDisabled: function() {
			return VXMobileStore.isBodyScrollDisabled();
		},

		disableBodyScroll: function(triggerName) {
			VXMobileActionCreator.disableBodyScroll(triggerName);
		},

		enableBodyScroll: function(triggerName) {
			VXMobileActionCreator.enableBodyScroll(triggerName);
		},

		closeMenu: function(menuId) {
			VXMobileActionCreator.closeMenu(menuId);
		},

		closeSubMenu: function(menuId, subMenuId) {
			VXMobileActionCreator.closeSubMenu(menuId, subMenuId);
		},

		toggleMenu: function(menuId) {
			VXMobileActionCreator.toggleMenu(menuId);
		},

		toggleSubMenu: function(menuId, subMenuId, isBlocking) {
			VXMobileActionCreator.toggleSubMenu(menuId, subMenuId, isBlocking);
		},

		updateSubMenu: function(menuId, subMenuId, data) {
			VXMobileActionCreator.updateSubMenu(menuId, subMenuId, data);
		},

		addMenuChangeListener: function(callback) {
			VXMobileStore.addMenuChangeListener(callback);
		},

		removeMenuChangeListener: function(callback) {
			VXMobileStore.removeMenuChangeListener(callback);
		},

		getMenu: function(menuId) {
			return VXMobileStore.getMenu(menuId);
		},

		getMenuOpen: function() {
			return VXMobileStore.getMenuOpen();
		},

		getSubMenu: function(menuId, subMenuId) {
			return VXMobileStore.getSubMenu(menuId, subMenuId);
		},

		getSubMenuOpen: function(menuId) {
			return VXMobileStore.getSubMenuOpen(menuId);
		},

		addContainerScrollListener: function(containerId, contentId, callback) {
			VXMobileActionCreator.addContainerScrollListener(containerId, contentId, callback);
		},

		removeContainerScrollListener: function(containerId, contentId) {
			VXMobileActionCreator.removeContainerScrollListener(containerId, contentId);
		},

		setRoute: function(routeName, routeArgs) {
			VXMobileActionCreator.setRoute(routeName, routeArgs);
		},

		getRouteName: function() {
			return VXMobileStore.getRouteName();
		},

		getRouteArgs: function() {
			return VXMobileStore.getRouteArgs();
		},

		addBodyOverflowHidden: function() {
			return VXMobileStore.addBodyOverflowHidden();
		},

		removeBodyOverflowHidden: function() {
			return VXMobileStore.removeBodyOverflowHidden();
		},

		showNavigation: function(withEffect = false) {
			VXMobileStore.showNavigation(withEffect);
		},

		hideNavigation: function(withEffect = false) {
			VXMobileStore.hideNavigation(withEffect);
		},

		Guest: {
			getAvatar: function() {
				return VXMobileStore.Guest.getAvatar();
			},

			getTileGridSettingsByType: function(type) {
				return VXMobileStore.Guest.getTileGridSettingsByType(type);
			},

			hasAvatar: function() {
				return VXMobileStore.Guest.hasAvatar();
			},

			storeTileGridSettingsByType: function(type, settings) {
				VXMobileActionCreator.Guest.storeTileGridSettingsByType(type, settings);
			},
		},
	},

	Contest: {
		init: function(contestsGridConfig, contestId, contestArea) {
			ContestStore.init(contestsGridConfig, contestId, contestArea);
		},

		addContestShouldOpenMediaChangeListener: function(callback) {
			ContestStore.addContestShouldOpenMediaChangeListener(callback);
		},

		removeContestShouldOpenMediaChangeListener: function(callback) {
			ContestStore.removeContestShouldOpenMediaChangeListener(callback);
		},

		addContestVotingChangeListener: function(callback) {
			ContestStore.addContestVotingChangeListener(callback);
		},

		removeContestVotingChangeListener: function(callback) {
			ContestStore.removeContestVotingChangeListener(callback);
		},

		addContestMediaVoting: function(contestId, mediaId, actorId, guestId, albumId) {
			ActionCreator.Contest.addContestMediaVoting(contestId, mediaId, actorId, guestId, albumId);
		},

		deleteContestMediaVoting: function(contestId, mediaId, actorId, guestId, albumId) {
			ActionCreator.Contest.deleteContestMediaVoting(contestId, mediaId, actorId, guestId, albumId);
		},

		addContestHeadChangeListener: function(callback) {
			ContestStore.addContestHeadChangeListener(callback);
		},

		removeContestHeadChangeListener: function(callback) {
			ContestStore.removeContestHeadChangeListener(callback);
		},

		addContestGridChangeListener: function(callback) {
			ContestStore.addContestGridChangeListener(callback);
		},

		removeContestGridChangeListener: function(callback) {
			ContestStore.removeContestGridChangeListener(callback);
		},

		addContestOpenAvsModalChangeListener: function(callback) {
			ContestStore.addContestOpenAvsModalChangeListener(callback);
		},

		removeContestOpenAvsModalChangeListener: function(callback) {
			ContestStore.removeContestOpenAvsModalChangeListener(callback);
		},

		getContestVoting: function(contestId) {
			return ContestStore.getContestVoting(contestId);
		},

		loadContestHead: function(contestId, language) {
			ActionCreator.Contest.loadContestHead(contestId, language);
		},

		getContestHeaderData: function(contestId) {
			return ContestStore.getContestHeaderData(contestId);
		},

		loadContestGridItems: function(contestId, contestArea, limit, offset, useCache, actorName) {
			ActionCreator.Contest.loadContestGridItems(contestId, contestArea, limit, offset, useCache, actorName);
		},

		getContestGridConfig: function(contestId, contestArea) {
			return ContestStore.getContestGridConfig(contestId, contestArea);
		},

		setShouldOpenMedia: function(shouldOpen, config) {
			ActionCreator.Contest.setShouldOpenMedia(shouldOpen, config);
		},

		getShouldOpenMedia: function() {
			return ContestStore.getShouldOpenMedia();
		},

		setShouldForwardToProfileVideo: function(value) {
			ActionCreator.Contest.setShouldForwardToProfileVideo(value);
		},

		getShouldForwardToProfileVideo: function() {
			return ContestStore.getShouldForwardToProfileVideo();
		},

		getShouldOpenMediaConfig: function() {
			return ContestStore.getShouldOpenMediaConfig();
		},

		setShouldOpenNextContestsModal: function(shouldOpen) {
			ActionCreator.Contest.setShouldOpenNextContestsModal(shouldOpen);
		},
		getShouldOpenNextContestsModal: function() {
			return ContestStore.getShouldOpenNextContestsModal();
		},
		/**
		 * @returns {Object}
		 */
		getVxqlContestsFromStore: function() {
			return VxqlStore.getVxqlContestsFromStore();
		},
		/**
		 * @param {Boolean} activeIsSpecial
		 * @param {Function} callback
		 */
		getVxqlContestsFromApi: function(activeIsSpecial, callback) {
			VxqlStore.getVxqlContestsFromApi(activeIsSpecial, callback);
		},

	},

	Blog: {
		/**
		 * @param {Number} categoryId
		 * @param {Function} callback
		 */
		setOnPostsRequestedListener: function(categoryId, callback) {
			BlogPostsStore.setOnPostsRequestedListener(categoryId, callback);
		},

		/**
		 * @param {Number} categoryId
		 * @param {Function} callback
		 */
		removeOnPostsRequestedListener: (categoryId, callback) => {
			BlogPostsStore.removeOnPostsRequestedListener(categoryId, callback);
		},

		/**
		 * @param {Number} categoryId
		 * @param {Function} callback
		 */
		setOnPostsReceivedListener: (categoryId, callback) => {
			BlogPostsStore.setOnPostsReceivedListener(categoryId, callback);
		},

		/**
		 * @param {Number} categoryId
		 * @param {Function} callback
		 */
		removeOnPostsReceivedListener: (categoryId, callback) => {
			BlogPostsStore.removeOnPostsReceivedListener(categoryId, callback);
		},

		/**
		 * @param {String} category
		 * @param {Number} year
		 * @param {Function} callback
		 */
		setOnArchivePostsReceivedListener: (category, year, callback) => {
			BlogPostsStore.setOnArchivePostsReceivedListener(category, year, callback);
		},

		/**
		 * @param {String} category
		 * @param {Number} year
		 * @param {Function} callback
		 */
		removeOnArchivePostsReceivedListener: (category, year, callback) => {
			BlogPostsStore.removeOnArchivePostsReceivedListener(category, year, callback);
		},

		/**
		 * @param {Function} callback
		 */
		setOnPmReceivedListener: function(callback) {
			BlogPostsStore.setOnPmReceivedListener(callback);
		},

		/**
		 * @param {Function} callback
		 */
		removeOnPmReceivedListener: function(callback) {
			BlogPostsStore.removeOnPmReceivedListener(callback);
		},

		/**
		 * @param {Function} callback
		 */
		setOnArchivesPmReceivedListener: function(callback) {
			BlogPostsStore.setOnArchivesPmReceivedListener(callback);
		},

		/**
		 * @param {Function} callback
		 */
		removeOnArchivesPmReceivedListener: function(callback) {
			BlogPostsStore.removeOnArchivesPmReceivedListener(callback);
		},

		/**
		 * @param {Number[]} ids
		 * @return {Promise<Array>}
		 */
		getActorsByIds: function(ids) {
			return BlogActorsStore.getActorsByIds(ids);
		},

		/**
		 * @param {Number} category
		 * @param {Number} page
		 * @param {Number} pageSize
		 */
		requestPosts(category, page = 1, pageSize = Constants.Blog.PageSize.default) {
			BlogPostsActionCreator.dispatchGetBlogPostsAction(category, page, pageSize);
		},

		getPostsFromStore: function() {
			return BlogPostsStore.getPostsFromStore();
		},
	},

	Gift: {
		init: function() {
			if (!Flux.Gift.isInitialized()) {
				MessengerGiftStore.init();
				initHelper.setReady(stores.GIFT);
			}
		},

		isInitialized: function() {
			return initHelper.isReady(stores.GIFT);
		},

		addGiftToMessage: function(giftId) {
			ActionCreator.Messenger.addGiftToMessage(giftId);
		},

		removeGiftFromMessage: function(giftId) {
			ActionCreator.Messenger.removeGiftFromMsg(giftId);
		},

		getCurrentMessagePrice: function() {
			return MessengerGiftStore.getCurrentMessagePrice();
		},

		getArrOfIdsFromGiftArr: function($gifts) {
			return MessengerGiftStore.getArrOfIdsFromGiftArr($gifts);
		},

		getPlaceHolderByGift: function(gift) {
			return MessengerGiftStore.getPlaceHolderByGift(gift);
		},

		getGifts: function() {
			return MessengerGiftStore.getGifts();
		},

		getGiftById: function(id) {
			return MessengerGiftStore.getGiftById(id);
		},

		getGiftsByCategoryId: function(id) {
			return MessengerGiftStore.getGiftsByCategoryId(id);
		},

		popGiftsInMessage: function() {
			return MessengerGiftStore.popGiftsInMessage();
		},

		getTmpCountOfBoughtGift: function(giftId) {
			return MessengerGiftStore.getTmpCountOfBoughtGift(giftId);
		},

		addGiftStoreInitializedChangeListener: function(callback) {
			MessengerGiftStore.addGiftStoreInitializedChangeListener(callback);
		},

		removeGiftStoreInitializedChangeListener: function(callback) {
			MessengerGiftStore.removeGiftStoreInitializedChangeListener(callback);
		},

		addGiftMessageChangeListener: function(callback) {
			MessengerGiftStore.addGiftMessageChangeListener(callback);
		},

		removeGiftMessageChangeListener: function(callback) {
			MessengerGiftStore.removeGiftMessageChangeListener(callback);
		},
	},

	ToyControl: {
		init: function() {
			if (!Flux.ToyControl.isInitialized()) {
				MessengerToyControlStore.init();
				initHelper.setReady(stores.TOYCONTROL);
			}
		},

		isInitialized: function() {
			return initHelper.isReady(stores.TOYCONTROL);
		},

		getToyControls: function() {
			return MessengerToyControlStore.getToyControls();
		},

		addToyControlStoreInitializedChangeListener: function(callback) {
			MessengerToyControlStore.addToyControlStoreInitializedChangeListener(callback);
		},

		removeToyControlStoreInitializedChangeListener: function(callback) {
			MessengerToyControlStore.removeToyControlStoreInitializedChangeListener(callback);
		},

	},

	Video: {
		Jumbotron:  {
			/**
			 * @param {Function} callback
			 */
			addConfigChangeListener: function(callback) {
				VideoJumbotronStore.addConfigChangeListener(callback);
			},
			/**
			 * @param {Function} callback
			 */
			removeConfigChangeListener: function(callback) {
				VideoJumbotronStore.removeConfigChangeListener(callback);
			},
			/**
			 * @return {Object|null}
			 */
			getConfig:  function(type = Constants.Video.Jumbotron.TYPE_VIDEO_STATION) {
				return VideoJumbotronStore.getConfig(type);
			},
			loadConfig: function(type = Constants.Video.Jumbotron.TYPE_VIDEO_STATION) {
				VideoJumbotronActionCreator.requestConfig(type);
			},
		},
		VideoStars: {
			getOrLoadVideoStars: function(first = 100, callback) {
				return VideoStarsStore.getOrLoadVideoStars(first, callback);
			},
		},
		Filter:     {
			/**
			 * @param {Number|String} gridId
			 * @param {Object} filter
			 */
			init: function(gridId, filter) {
				VideoFilterStore.init(gridId, filter);
			},
			/**
			 * @param {Number|String} gridId
			 * @return {*}
			 */
			getById: function(gridId) {
				return VideoFilterStore.getFilter(gridId);
			},
			/**
			 * @param {Function} handler
			 */
			addFilterChangeListener: function(handler) {
				VideoFilterStore.addFilterChangeListener(handler);
			},
			/**
			 * @param {Function} handler
			 */
			removeFilterChangeListener: function(handler) {
				VideoFilterStore.removeFilterChangeListener(handler);
			},
			/**
			 * @param {Function} callback
			 */
			addFilterToggledListener: function(callback) {
				VideoFilterStore.addFilterToggledListener(callback);
			},
			/**
			 * @param {Function} callback
			 */
			removeFilterToggledListener: function(callback) {
				VideoFilterStore.removeFilterToggledListener(callback);
			},
			/**
			 * @param {String|Number} gridId
			 * @param {Number} page
			 */
			changePage: function(gridId, page) {
				VideoFilterActionCreator.setPage(gridId, page);
			},
			/**
			 * @param {String|Number} gridId
			 * @param {Number} pageSize
			 */
			setPageSize: function(gridId, pageSize) {
				VideoFilterActionCreator.setPageSize(gridId, pageSize);
			},
			/**
			 * @param {String|Number} gridId
			 */
			toggle: function(gridId) {
				VideoFilterStore.toggleExpanded(gridId);
			},
		},
	},

	FooterLinks: {
		getSocialLinks:            function() {
			return FooterLinksStore.getSocialLinks();
		},
		getZendeskLink:            function(language) {
			return FooterLinksStore.getZendeskLink(language);
		},
		getVxModelsLink:           function(language) {
			return FooterLinksStore.getVxModelsLink(language);
		},
		getVxCashLink:             function() {
			return FooterLinksStore.getVxCashLink();
		},
		getBecomeAVisitXModelLink: function(language) {
			return FooterLinksStore.getBecomeAVisitXModelLink(language);
		},
        getReportContentLink: function(language) {
			return FooterLinksStore.getReportContentLink(language);
		}
	},

	ClashOfCams: {
		getClashOfCamsData:            function(clashId, callback) {
			return ClashOfCamsStore.getClashOfCamsData(clashId, callback);
		},
		addClashOfCamsVote:            function(challengeId, teamId, callback) {
			return ClashOfCamsStore.addClashOfCamsVote(challengeId, teamId, callback);
		},
		removeClashOfCamsVote:         function(challengeId, callback) {
			return ClashOfCamsStore.removeClashOfCamsVote(challengeId, callback);
		},
		addClashOfCamsVoteListener:    function(callback) {
			return ClashOfCamsStore.addClashOfCamsVoteListener(callback);
		},
		removeClashOfCamsVoteListener: function(callback) {
			return ClashOfCamsStore.removeClashOfCamsVoteListener(callback);
		},
	},

	Banner: {
		loadBanner: function(mobile, routeName, routeArgs, callback) {
			VxqlStore.loadBanner(mobile, routeName, routeArgs, callback);
		},
	},

	UserActivities: {
		getUserActivityData:   function(activityId, callback) {
			return VxqlStore.getUserActivityData(activityId, callback);
		},
		setUserActivityReward: function(activityId, rewardId, callback) {
			return VxqlStore.setUserActivityReward(activityId, rewardId, callback);
		},
	},

	EasterEvent: {
		getEasterEvent: function(eventId, callback) {
			return VxqlStore.getEasterEvent(eventId, callback);
		},
	},

	MessengerContent: {
		getMessengerContent: function(first, offset, filter, order, callback) {
			return VxqlStore.getMessengerContent(first, offset, filter, order, callback);
		},
		addMessengerContentChangeListener(callback) {
			return NotificationStore.addMessengerContentChangeListener(callback);
		},
		removeMessengerContentChangeListener(callback) {
			return NotificationStore.removeMessengerContentChangeListener(callback);
		},
	},

	Feed:          {
		toggleOverlay:                  function(postId, overlayType, ImageId) {
			ActionCreator.Feed.toggleOverlay(postId, overlayType, ImageId);
		},
		addOverlayChangeListener:       function(callback) {
			FeedStore.addOverlayChangeListener(callback);
		},
		removeOverlayChangeListener:    function(callback) {
			FeedStore.removeOverlayChangeListener(callback);
		},
		toggleLike:                     function(postId) {
			ActionCreator.Feed.toggleLike(postId);
		},
		addUpdatePostChangeListener:    function(callback) {
			FeedStore.addUpdatePostChangeListener(callback);
		},
		removeUpdatePostChangeListener: function(callback) {
			FeedStore.removeUpdatePostChangeListener(callback);
		},
		triggerUpdatePost:              function(postId) {
			ActionCreator.Feed.triggerUpdatePost(postId);
		},
		addSetPostsChangeListener:      function(callback) {
			FeedStore.addSetPostsChangeListener(callback);
		},
		removeSetPostsChangeListener:   function(callback) {
			FeedStore.removeSetPostsChangeListener(callback);
		},
		triggerSetPosts:                function() {
			ActionCreator.Feed.triggerSetPosts();
		},
		addLikeChangeListener:          function(callback) {
			FeedStore.addLikeChangeListener(callback);
		},
		removeLikeChangeListener:       function(callback) {
			FeedStore.removeLikeChangeListener(callback);
		},
		getFeedData:                    function(args, callback, withTotal) {
			FeedStore.getFeedData(args, callback, withTotal);
		},
		getFeedTotals:                  function(args, callback) {
			FeedStore.getFeedTotals(args, callback);
		},
		getFeedPost:                    function(postId, callback) {
			FeedStore.getFeedPost(postId, callback);
		},
		viewedPost:                     function(postId, callback) {
			FeedStore.viewedPost(postId, callback);
		},
		buyPost:                        function(postId, callback) {
			FeedStore.buyPost(postId, callback);
		},
		likePost:                       function(postId, callback) {
			FeedStore.likePost(postId, callback);
		},
		unlikePost:                     function(postId, callback) {
			FeedStore.unlikePost(postId, callback);
		},
		commentPost:                    function(postId, text, repliesToCommentId, callback) {
			FeedStore.commentPost(postId, text, repliesToCommentId, callback);
		},
		deleteComment:                  function(commentID, callback) {
			FeedStore.deleteComment(commentID, callback);
		},
		addTip:                         function(postId, amount, trackerContext, callback) {
			FeedStore.addTip(postId, amount, trackerContext, callback);
		},
		setPosts:                       function(posts, feedType, hasNextPage) {
			FeedStore.setPosts(posts, feedType, hasNextPage);
		},
		getPosts:                       function(feedType) {
			return FeedStore.getPosts(feedType);
		},
		getHasNextPage:                 function(feedType) {
			return FeedStore.getHasNextPage(feedType);
		},
		setSelectedPostId:              function(id, feedType) {
			FeedStore.setSelectedPostId(id, feedType);
		},
		getSelectedPostId:              function(feedType) {
			return FeedStore.getSelectedPostId(feedType);
		},
		setCurrentTime(id, time) {
			FeedStore.setCurrentTime(id, time);
		},
		removeCurrentTime(id) {
			FeedStore.removeCurrentTime(id);
		},
		getCurrentTime(id) {
			return FeedStore.getCurrentTime(id);
		},
		addBlockVideoPlayChangeListener:    function(callback) {
			FeedStore.addBlockVideoPlayChangeListener(callback);
		},
		removeBlockVideoPlayChangeListener: function(callback) {
			FeedStore.removeBlockVideoPlayChangeListener(callback);
		},
		setBlockVideoMute:                  function(isMuted) {
			FeedStore.setBlockVideoMute(isMuted);
		},
		getBlockVideoMute:                  function() {
			return FeedStore.getBlockVideoMute();
		},
		toggleBlockVideoPlay:               function(postId, isMuted = true) {
			ActionCreator.Feed.toggleBlockVideoPlay(postId, isMuted);
		},
		addVideoPlayChangeListener:         function(callback) {
			FeedStore.addVideoPlayChangeListener(callback);
		},
		removeVideoPlayChangeListener:      function(callback) {
			FeedStore.removeVideoPlayChangeListener(callback);
		},
		setPlayingVideoId:                  function(postId) {
			ActionCreator.Feed.setPlayingVideoId(postId);
		},
		setVideoMute:                       function(isMuted) {
			FeedStore.setVideoMute(isMuted);
		},
		getVideoMute:                       function() {
			return FeedStore.getVideoMute();
		},
	},
	CollectionsV2: {
		getCollections(args, callback) {
			VxqlStore.getCollectionsV2(args, callback);
		}
	},
	Legal:         {
		getLegalTexts(filter, first, offset, callback) {
			VxqlStore.getLegalTexts(filter, first, offset, callback);
		}
	},
	Slotmachine:   {
		getActorsByIds(ids, callback) {
			SlotmachineStore.getActorsByIds(ids, callback);
		},
		getVideosByIds(ids, callback) {
			SlotmachineStore.getVideosByIds(ids, callback);
		}
	},
	Seo: {
		getSeo(tags, callback) {
			VxqlStore.getSeo(tags, callback);
		}
	},
	Spinwheel: {
		getSpinwheelInfo(callback, type = Constants.Spinwheel.Types.default) {
			VxqlStore.getSpinwheelInfo(callback, type);
		},
		doSpinwheelSpin(callback, type = Constants.Spinwheel.Types.default) {
			VxqlStore.doSpinwheelSpin(callback, type);
		},
		getLoyaltyPointsRewardHistory: function(callback) {
			VxqlStore.getLoyaltyPointsRewardHistory(callback);
		},
		redeemLoyaltyPointsReward: function(id, callback) {
			VxqlStore.redeemLoyaltyPointsReward(id, callback);
		},
        tryMigrate: function(callback) {
            VxqlStore.tryLoyaltyPointsMigrate(callback);
        },
        claimCoins: function(callback) {
            VxqlStore.claimCoins(callback);
        }
	}
};

window.Flux = Flux;

Flux.ChatExitCodes = ChatExitCodes;
Flux.Constants     = Constants;

export default Flux;
