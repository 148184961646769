import FluxEventEmitter from './../FluxEventEmitter';
import Flux             from "../Flux";
import assign           from "object-assign";
import {generateHash}   from "../../utils/CommonUtils";

const ChangeEvents = {
	searchResult:         'searchResult',
	currentSearchPattern: 'currentSearchPattern',
};

const CACHE_TTL     = 300000; // 5 min
let _cleanerRunning = false;

let _lastResult = {
	items: [],
	total: 0,
	type:  '',
};

const _cache = {
	actors: [],
	videos: [],
	photos: [],
	tv:     [],
	blog:   [],
};

let _lastRequest          = null;
let _currentSearchPattern = null;

function getVxqlFilter(filter) {
	const vxqlFilter = Object.assign({}, filter);

	delete vxqlFilter.sorting;

	return vxqlFilter;
}

function processSearch(searchPattern, category, filter, offset = 0, first = 0, saveRequest = false) {
	const vxqlFilter       = getVxqlFilter(filter);
	const searchParameters = {searchPattern, category, filter, offset, first};

	if (saveRequest) {
		_lastRequest = searchParameters;
	}

	const requestHash = generateHash(JSON.stringify(searchParameters));
	if (_cache[category][requestHash]) {
		_lastResult = _cache[category][requestHash].searchResult;

		SearchStore.emitSearchResultChange(_lastResult);
		return;
	}

	switch (category) {
		case Flux.Constants.SearchResults.Categories.actors.name:
			Flux.Vxql.processActorSearch(
				searchPattern,
				result => (
					processSearchResult(
						result,
						searchParameters
					)
				),
				first > 0 ? first : Flux.Constants.SearchResults.Categories.actors.itemsPerPage,
				offset,
				filter.sorting,
				vxqlFilter
			);
			break;
		case Flux.Constants.SearchResults.Categories.videos.name:
			Flux.Vxql.processVideoSearch(
				searchPattern,
				result => (
					processSearchResult(
						result,
						searchParameters
					)
				),
				first > 0 ? first : Flux.Constants.SearchResults.Categories.videos.itemsPerPage,
				offset,
				filter.sorting,
				vxqlFilter
			);
			break;
		case Flux.Constants.SearchResults.Categories.tv.name:
			Flux.Vxql.processMediathekSearch(
				searchPattern,
				result => (
					processSearchResult(
						result,
						searchParameters
					)
				),
				first > 0 ? first : Flux.Constants.SearchResults.Categories.tv.itemsPerPage,
				offset,
				filter.sorting,
				vxqlFilter
			);
			break;
		case Flux.Constants.SearchResults.Categories.blog.name:
			Flux.Vxql.processBlogSearch(
				searchPattern,
				result => (
					processSearchResult(
						result,
						searchParameters
					)
				),
				first > 0 ? first : Flux.Constants.SearchResults.Categories.blog.itemsPerPage,
				offset,
				filter.sorting,
				vxqlFilter
			);
			break;
		default:
			break;
	}

}

function initCacheCleaner() {
	if (!_cleanerRunning) {
		Cleaner();

		_cleanerRunning = true;
	}
}

function sleep(ms) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

async function Cleaner() {
	await sleep(CACHE_TTL);

	for (;;) {
		const now      = Date.now();
		let entryCount = 0;

		for (const category in _cache) {
			for (const key in _cache[category]) {
				if (_cache[category][key].expire < now) {
					delete _cache[category][key];
				} else {
					entryCount++;
				}
			}
		}

		if (entryCount === 0) {
			_cleanerRunning = false;
			return;
		}

		await sleep(5000);
	}
}

function processSearchResult(result, searchParameter) {
	if (result.data.search) {
		_lastResult = result.data.search[Object.keys(result.data.search)[0]];

		const hash                             = generateHash(JSON.stringify(searchParameter));
		_cache[searchParameter.category][hash] = {expire: Date.now() + CACHE_TTL, searchResult: _lastResult};

		initCacheCleaner();
	} else {
		_lastResult = null;
	}

	SearchStore.emitSearchResultChange(_lastResult);
}

const SearchStore = assign({}, FluxEventEmitter.prototype, {

	doSearch: function(searchPattern, category, filter, offset = 0, first = 0, saveRequest = false) {
		processSearch(searchPattern, category, filter, offset, first, saveRequest);
	},

	getLastRequest: function() {
		return _lastRequest;
	},

	getCurrentSearchPattern: function() {
		return _currentSearchPattern;
	},

	setCurrentSearchPattern: function(searchPattern) {
		_currentSearchPattern = searchPattern;
		this.emit(ChangeEvents.currentSearchPattern);
	},

	addCurrentSearchpatternChangeListener: function(callback) {
		this.on(ChangeEvents.currentSearchPattern, callback);
	},

	removeCurrentSearchpatternChangeListener: function(callback) {
		this.removeListener(ChangeEvents.currentSearchPattern, callback);
	},

	addSearchResultListener: function(callback) {
		this.on(ChangeEvents.searchResult, callback);
	},

	removeSearchResultListener: function(callback) {
		this.removeListener(ChangeEvents.searchResult, callback);
	},

	emitSearchResultChange: function(result) {
		this.emit(ChangeEvents.searchResult, result);
	},

	clearCache: function(categories) {
		for (let i = 0; i < categories.length; i++) {
			if (Object.hasOwn(_cache, categories[i])) {
				_cache[categories[i]] = [];
			}
		}
	},

    clearVideoCache: function() {
        _cache[Flux.Constants.SearchResults.Categories.videos.name] = [];
    }

});


export default SearchStore;
